import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { appAnimations } from '../../constants/app-animations';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ui-tooltip-text',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './tooltip-text.component.html',
  animations: appAnimations,
})
export class TooltipTextComponent implements OnInit, OnDestroy {
  @Input() public position: BehaviorSubject<string> = new BehaviorSubject(
    'top'
  );
  private observePosition = this.position.asObservable();
  @Input() public large: boolean = false;
  @Input() public light: boolean = true;
  @Input() public underline: boolean = false;
  @Input() public forceHide: boolean = false;
  @Input() public defaultCursor: boolean = false;
  @Input() public useDynamicPositioning: boolean = false;
  @Input() public text: string = '';
  public animationDirection: RegExp = /(-left)|(-right)|(-center)/;

  private readonly destroy = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit() {
    this.observePosition
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.cdRef.detectChanges());
  }

  public ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
