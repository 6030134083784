import { Inject, Injectable, Injector, Optional } from '@angular/core';
import {
  LocationService,
  HoneyBadgerService,
  HoneyBadgerServiceMock,
} from '@callrail/looky/util';
import { take } from 'rxjs/operators';

import { ErrorHelperService } from './error-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorReporterService {
  private hb: any;

  constructor(
    private helper: ErrorHelperService,
    private injector: Injector, // to prevent real hb service from eager loading in dev|tests
    private locationService: LocationService,
    @Optional() @Inject('env') private env?: any // from looky apps
  ) {
    this.hb = this.hbService.hb;
    this.hb.configure(this.hbSettings);
    this.hb.beforeNotify((error) => error);
  }

  public report(error) {
    error = this.helper.helpfulNamedError(error);
    this.helper.isMySpaFresh.pipe(take(1)).subscribe(
      (isSpaFresh) => {
        if (isSpaFresh) {
          this.logAndNotify(error);
        }
      },
      (err) => {
        err.name = `SpaFreshnessError::${err.name}`;
        this.logAndNotify(err);
      }
    );
  }

  private logAndNotify(error) {
    // eslint-disable-next-line no-console
    console.error('error', error);
    this.hb.notify(error);
  }

  private get isDevOrTestEnv() {
    return this.env && (this.env.development || this.env.test);
  }

  // we are lazy loading the honeybadger service below with injector because we dont want to load the real hb
  // service at all during dev or test since that honeybadger-js import throws errors regardless if its setup or not
  private get hbService() {
    if (this.isDevOrTestEnv) {
      return this.injector.get(HoneyBadgerServiceMock);
    } else {
      return this.injector.get(HoneyBadgerService);
    }
  }

  // Might need to be updated to the NestJS env //
  private get backendEnv() {
    // could get here in looky app if an err was thrown prior to app-init logic being resolved
    // since we dont know what the backend env is here, make an educated guess based on the url
    const host = this.locationService.host(); // app.staging-callrail.com
    return host.match(/-callrail/) ? 'staging' : 'production';
  }

  private get hbSettings() {
    if (this.isDevOrTestEnv) {
      return {};
    } else {
      // always use the nest backend env as the "environment" value
      return {
        apiKey: 'd5b41121',
        breadcrumbsEnabled: {
          console: false,
        },
        maxErrors: 20,
        environment: this.backendEnv,
        revision: process.env.REVISION,
        enableUnhandledRejection: false,
      };
    }
  }
}
