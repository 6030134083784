import { enableProdMode } from '@angular/core';
import { configureUserPool } from '@callrail/looky/anubis/login/shared';
import { AppModule } from './app/app.module';
import { env } from './environments/environment';

if (env.production) {
  enableProdMode();
}

configureUserPool(AppModule);
