import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

type EnvName = 'production' | 'staging' | 'development' | 'test';

@Injectable({
  providedIn: 'root',
})
export class AppContextService {
  constructor(
    @Optional() @Inject(APP_BASE_HREF) private baseHref?: string,
    @Optional() @Inject('env') private env?: any
  ) {}

  public get currentAppName(): string {
    return this.baseHref ? this.baseHref.split('/')[1] : 'default';
  }

  public get isLookyApp(): boolean {
    return !!this.env;
  }

  public get isProd(): boolean {
    return this.env.production && !this.env.staging;
  }

  public get envName(): EnvName {
    if (this.env?.staging) {
      return 'staging';
    } else if (this.env?.production) {
      return 'production';
    } else if (this.env?.development) {
      return 'development';
    } else {
      return 'test';
    }
  }
}
