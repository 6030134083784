<form (ngSubmit)="singleSignOn()">
  <label for="email">Your email</label>
  <input
    type="email"
    id="email"
    [formControl]="emailInput" />
  <button class="btn btn--primary">Single Sign On</button>
</form>

<div class="footer-links">
  <a href="/authenticate/">Return to standard login</a>
</div>
