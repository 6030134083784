import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class FullstoryTestingCookieService {
  constructor(private cookieService: CookieService) {}

  public hasFsTestingCookie(): boolean {
    return this.cookieService.check('fs_testing');
  }

  // Add cookie that will expire in 24hrs
  public setFsTestingCookie() {
    this.cookieService.set(
      'fs_testing',
      'true',
      new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      '/'
    );
  }

  public deleteFsTestingCookie() {
    this.cookieService.delete('fs_testing', '/');
  }
}
