/* eslint-disable no-underscore-dangle */

/**
 * @cabooseInclude
 * @hierarchy [controls-user-input][Form Controls]
 * @title [File Importer]
 */

// eslint-disable-next-line max-classes-per-file
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { CurrentContextService } from '../../services/current-context/current-context.service';
import { UserModel } from '../../models/user.model';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { ToSentencePipe } from '@callrail/looky/ui';
import { EMPTY } from 'rxjs';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'unrecognized-file-type',
})
export class UnrecognizedFileTypeDirective {}
@Component({
  selector: 'core-file-importer',
  templateUrl: './file-importer.component.html',
  styleUrls: ['./file-importer.component.scss'],
})
export class FileImporterComponent {
  @Input() public modalTitle = 'Import File';
  @Input() public maxByteFileSize = 0;
  @Input() public maxWidth = 'initial';
  /** One or more of these unique file extensions are allowed, separate each with a comma. */
  @Input() public acceptedFileTypes: '.csv' | '.mp3' | '.wav' | '.key';
  @Input() public submit;
  @Input() public openModalButtonText = 'Import';
  @Input() public openModalButtonDisabled = false;
  @Input() public classNames: string;
  @Input() public uploadButtonText = 'Import';
  @Input() public displayType: 'modal' | 'component' = 'modal';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onSuccess = new EventEmitter<any>();
  /**
   * Reference to the hidden input[type=file] element that's
   * used when the use clicks the 'Add File' button.
   */
  @Output() public file_selected = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  public modalOpen = false;
  public file: File;
  public error: string;
  public readyToImport = false;
  public importInProgress = false;
  public s3URL: string;
  public s3BucketPath: string;

  constructor(
    private context: CurrentContextService,
    private http: HttpClient
  ) {}

  public openModal(): void {
    this.modalOpen = true;
    this._fetchS3URL();
  }

  public closeModal(): void {
    this.reset();
    this.modalOpen = false;
  }

  public reset(): void {
    this.fileInput.nativeElement.value = null;
    this.file = null;
    this.error = null;
    this.readyToImport = false;
    this.importInProgress = false;
  }

  public openFilePicker(): void {
    this.fileInput.nativeElement.click();
  }

  public onFileDrop($event): void {
    this.preventFileOpen($event);
    const droppedItem = $event.dataTransfer.items[0];
    if (droppedItem.kind === 'file') {
      const file = droppedItem.getAsFile();
      this._processFile(file);
    }
  }

  public preventFileOpen($event): void {
    $event.preventDefault();
  }

  public onFileSelected(): void {
    const file = this.fileInput.nativeElement.files[0];
    // file can be undefined here if the user clicks the 'Add File' button
    // and clicks the 'Cancel' button in the browsers native file picker.
    // if they already had a file selected, we don't want to unselect that file
    if (!file) {
      return;
    }

    this._processFile(file);
  }

  public _processFile(file: File): void {
    this.readyToImport = false;
    this.file = file;
    this.file_selected.emit(file);

    this._runClientSideValidations();

    // if client side validations failed, short circuit so we don't
    // upload to s3 and submit for server side validation
    if (this.error) {
      return;
    }

    this.readyToImport = true;
  }

  /**
   * The UI only has space for one error at a time. So _errorWithFile
   * should only be called once.
   */
  public _runClientSideValidations(): void {
    this.error = null;
    if (this.file.size > this.maxByteFileSize) {
      this._errorWithFile('The file you uploaded is too large.');
    } else if (!this.isValidFileType) {
      const okFileTypesString = new ToSentencePipe().transform(
        this.okFileTypes,
        'or'
      );
      this._errorWithFile(`Your upload file must be a ${okFileTypesString}.`);
    }
  }

  private get okFileTypes(): string[] {
    return this.acceptedFileTypes.split(',');
  }

  private get isValidFileType(): boolean {
    return this.okFileTypes.some((type) => this.file.name.includes(type));
  }

  public _errorWithFile(error) {
    this.fileInput.nativeElement.value = null;
    this.file = null;
    this.readyToImport = false;
    this.importInProgress = false;
    this.error = this.detectErrorType(error);
  }

  private detectErrorType(error: string): string {
    if (
      error[0] &&
      error[0].includes("We didn't recognize the file type you provided")
    ) {
      return 'unrecognized file type';
    }
    return (
      error ||
      "We can't upload that file. Please contact our support team for help."
    );
  }

  public _fetchS3URL(): void {
    this.context.user
      .pipe(
        switchMap((user: UserModel) =>
          this.http.get(`/user_uploads/${user.agency_user_mid}/new`)
        ),
        take(1)
      )
      .subscribe((resp: any) => {
        this.s3BucketPath = resp.bucket_path;
        this.s3URL = resp.url;
      });
  }

  public import(): void {
    this.importInProgress = true;
    this.http
      .put(this.s3URL, this.file)
      .pipe(
        mergeMap(() => this.submit(this.submitToParams)),
        catchError((resp) => {
          this._errorWithFile(resp?.error?.errors);
          return EMPTY;
        })
      )
      .subscribe((resp: any) => {
        this.onSuccess.emit(resp);
        this.closeModal();
      });
  }

  public get submitToParams(): {
    url: string;
    bucket_path: string;
    name: string;
  } {
    return {
      name: this.file.name,
      url: this.s3URL,
      bucket_path: this.s3BucketPath,
    };
  }
}
