import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { HeaderTokenAuthTokenResponse } from '../../interfaces/header-token-auth-token-response';
import { SalesforceCanvasService } from '../salesforce-canvas/salesforce-canvas.service';
import { SalesforceSignedRequestsService } from '../salesforce-signed-requests/salesforce-signed-requests.service';
import { RingCentralIframeService } from '../ring-central-iframe/ring-central-iframe.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SemrushIframeService } from '../semrush-iframe/semrush-iframe.service';

type IdentityProvider = 'salesforce' | 'call_rail' | 'semrush';

@Injectable({
  providedIn: 'root',
})
export class HeaderTokenAuthService {
  public jwt: string;
  public jwtExpiresAt: number;
  public idp: IdentityProvider;
  private tokenPath: string;
  private isRefreshing$ = new BehaviorSubject(false);

  constructor(
    private salesforceCanvasService: SalesforceCanvasService,
    private salesforceSignedRequestsService: SalesforceSignedRequestsService,
    private semrushIframesService: SemrushIframeService,
    private ringCentralIframeService: RingCentralIframeService
  ) {}

  public get isRefreshing(): boolean {
    return this.isRefreshing$.getValue();
  }

  public set isRefreshing(val: boolean) {
    this.isRefreshing$.next(val);
  }

  public authenticate(
    tokenPath?: string
  ): Observable<HeaderTokenAuthTokenResponse | HttpErrorResponse> {
    this.tokenPath = this.tokenPath || tokenPath; // eslint-disable-line no-underscore-dangle
    return this.tokenExchange.pipe(
      tap((resp: HeaderTokenAuthTokenResponse) => {
        this.jwt = resp.jwt;
        this.jwtExpiresAt = resp.expires_at;
      })
    );
  }

  public get freshToken(): Observable<
    boolean | HeaderTokenAuthTokenResponse | HttpErrorResponse
  > {
    if (this.isRefreshing) {
      return this.isRefreshing$.pipe(
        filter((isRefreshing) => !isRefreshing),
        take(1)
      );
    } else {
      this.isRefreshing = true;
      if (this.idp === 'call_rail') {
        this.jwt = null;
      }
      return this.authenticate().pipe(tap(() => (this.isRefreshing = false)));
    }
  }

  public isTokenPath(path: string): boolean {
    return this.cleanPath(path) === this.cleanPath(this.tokenPath);
  }

  public get callTrkAuthorizationHeader(): string {
    switch (this.idp) {
      case 'call_rail':
        return 'SessionTokenAuth';
      case 'semrush':
        return 'SemrushTokenAuth';
      case 'salesforce':
      default:
        return 'SalesforceTokenAuth';
    }
  }

  // return 'foo.json' given `/api/foo.json?w=1`, `/foo.json?w=1`, or `foo.json?w=1`
  private cleanPath(path: string): string {
    return path.replace(/^\/?(?:api)?(.*)\?.*/, '$1').replace(/^\//, '');
  }

  private get tokenExchange(): Observable<
    HeaderTokenAuthTokenResponse | HttpErrorResponse
  > {
    switch (this.idp) {
      case 'salesforce':
        return this.salesforceCanvasService.refreshSignedRequest.pipe(
          switchMap((signedRequest: string) => {
            const params = {
              signed_request: signedRequest,
              user_id: localStorage.getItem('userRid'),
            };
            return this.salesforceSignedRequestsService.token(
              this.tokenPath,
              params
            );
          })
        );
      case 'semrush':
        return this.semrushIframesService.providerToken.pipe(
          switchMap((providerToken: string) => {
            return this.semrushIframesService.tokenExchange(
              this.tokenPath,
              providerToken
            );
          })
        );
      case 'call_rail':
        return this.ringCentralIframeService.tokenExchange(this.tokenPath);
      default:
        return throwError(() => {
          return new HttpErrorResponse({
            error: 'HeaderTokenAuthService#tokenExchange not implemented',
          });
        });
    }
  }
}
