import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  CssVariablesService,
  AlertFlashService,
  AlertType,
} from '@callrail/looky/ui';
import { AssetsService } from '@callrail/looky/ui';
import {
  AppRoutes,
  DocumentRefService,
  LocationService,
} from '@callrail/looky/util';
import { isEmpty } from 'lodash';
import { map, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { ColorsService } from './services/colors/colors.service';
import { WhiteLabelAttributes } from './services/colors/white-label-attributes';

@Component({
  selector: 'anubis-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public colors$ = this.colorsService.colors$;
  public isWl$ = this.colors$.pipe(
    map((wlAttrs) => this.isWhiteLabel(wlAttrs)),
    tap((isWl) => this.setupFavicon(isWl))
  );

  public logo$ = this.colors$.pipe(map((colors) => this.logo(colors)));
  public logoAlt$ = this.colors$.pipe(map((colors) => this.logoAlt(colors)));

  constructor(
    private router: Router,
    private cssVariablesService: CssVariablesService,
    public assetsService: AssetsService,
    private documentRefService: DocumentRefService,
    private colorsService: ColorsService,
    private zone: NgZone,
    private alertFlash: AlertFlashService,
    private locationService: LocationService
  ) {
    this.router.initialNavigation();
    this.showCrossAppAlerts();
  }

  private get document() {
    return this.documentRefService.nativeDocument();
  }

  private addElement(
    type: 'link' | 'script',
    location: 'head' | 'body',
    attrs: any = {}
  ): void {
    const document = this.document;
    const el = document.createElement(type);
    for (const key of Object.keys(attrs)) {
      el[key] = attrs[key];
    }
    document[location].appendChild(el);
  }

  private isWhiteLabel(colors): boolean {
    return !isEmpty(colors);
  }

  private setupFavicon(isWl: boolean): void {
    if (isWl) {
      this.addElement('link', 'head', {
        rel: 'icon',
        type: 'image/vnd.microsoft.icon',
        href: this.assetsService.resolve('white_label_favicon.ico'),
      });
      this.addElement('link', 'head', {
        rel: 'apple-touch-icon-precomposed',
        href: this.assetsService.resolve('whitelabel-iphone-bookmark-icon.png'),
      });
    } else {
      this.addElement('link', 'head', {
        rel: 'icon',
        type: 'image/vnd.microsoft.icon',
        href: this.assetsService.resolve('favicon.ico'),
      });
      this.addElement('link', 'head', {
        rel: 'apple-touch-icon-precomposed',
        href: this.assetsService.resolve('iphone-bookmark-icon.png'),
      });
    }
  }

  private logo(wlAttrs: WhiteLabelAttributes): string {
    if (this.isWhiteLabel(wlAttrs)) {
      return wlAttrs.white_label_login_logo_url;
    } else {
      return this.assetsService.resolve('logo-dark.svg');
    }
  }

  private logoAlt(wlAttrs: WhiteLabelAttributes): string {
    if (this.isWhiteLabel(wlAttrs)) {
      return wlAttrs.white_label_site_name + ' Logo';
    } else {
      return 'CallRail Logo';
    }
  }

  private checkForAlertsInQueryParameters() {
    const params = this.locationService.search();
    const alertType: string = params['alert[type]']
      ? params['alert[type]']
      : null;
    const alertText: string = params['alert[text]']
      ? params['alert[text]']
      : null;
    if (alertType && alertText) {
      this.alertFlash.addAlert({
        type: alertType as AlertType,
        text: alertText as string,
        timeout: 10000,
        crossApp: true,
      });
    }
    this.locationService.removeParams(['alert[type]', 'alert[text]']);
  }

  private showCrossAppAlerts() {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      // Once angular is stable and we've stopped routing, then show the alerts.
      // This helps prevent routing from "swallowing" the alerts
      this.checkForAlertsInQueryParameters();
      this.alertFlash.showAlertsFromStorage();
    });
  }
}
