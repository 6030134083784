import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Alert, AlertFlashService } from '@callrail/looky/ui';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Don't use this component in isolation. It is designed to be globally
 * available in the V2 and Account Center packs. If you need to flash an
 * alert, inject the {AlertFlashService} and call {#addAlert} on it,
 * passing in an {Alert} which will then display via the global component
 * in the app root component.
 */
@Component({
  selector: 'sh-layout-alert-flash',
  templateUrl: './layout-alert-flash.component.html',
})
export class LayoutAlertFlashComponent implements OnDestroy {
  public alerts: Alert[];
  public routerEventsSubscription: Subscription;

  constructor(
    private alertService: AlertFlashService,
    private router: Router
  ) {
    this.alerts = alertService.alerts;
    this.subscribeToRouteChanges();
  }

  public close(alert) {
    this.alertService.clearAlert(alert);
  }

  public classForAlertType(type: string): string {
    const alertTypeClasses = {
      success: 'alert-success',
      danger: 'alert-danger',
      warning: 'alert-warning',
      info: 'alert-info',
    };

    return alertTypeClasses[type] || 'alert-warning';
  }

  public iconClassFor(alertType: string): string {
    const alertTypeIconClasses = {
      success: 'icon-alert-success',
      danger: 'icon-alert-error',
      warning: 'icon-alert-error',
      info: 'icon-alert-info',
    };

    return alertTypeIconClasses[alertType] || 'icon-alert-error';
  }

  public ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe();
  }

  private subscribeToRouteChanges(): void {
    this.routerEventsSubscription = this.router.events
      .pipe(
        // use navigation end so that we avoid closing alerts for nav
        // cycles that get cancelled, such as redirects esp from guards
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Need to make a copy or not all alerts will be removed.
        // Since closing an alert mutates the array, if you have a two-item
        // array and remove the first item, the array no longer has a second
        // item so the forEach will stop.
        const alertsToIterate = [...this.alerts];
        alertsToIterate.forEach((currentAlert: Alert) => {
          if (currentAlert.showAfterRouteChange) {
            currentAlert.showAfterRouteChange = false;
          } else {
            this.close(currentAlert);
          }
        });
      });
  }
}
