import dayjs from 'dayjs';

export const dateFormat = 'YYYY-MM-DD';
export const dateDisplayFormat = 'MMM D, YYYY';

export interface DateRangePresets {
  display: string;
  id: DateRangePresetsKeys;
  range: dayjs.Dayjs[];
}

export interface DateRange {
  display?: string;
  id: string;
  since?: string;
  until?: string;
  name?: string;
}

export interface CompareRangePresets {
  id: CompareRangePresetsKeys;
  display: string;
}

export type CompareRangePresetsKeys =
  | 'previous_period'
  | '3_months_ago'
  | '6_months_ago'
  | '1_year_ago';

export type DateRangePresetsKeys =
  | 'recent'
  | 'today'
  | 'yesterday'
  | 'last_7_days'
  | 'last_30_days'
  | 'this_month'
  | 'last_month'
  | 'this_year'
  | 'last_year';
