export function localStorageEnabled(): boolean {
  try {
    localStorage.setItem('localStorageEnabled', 'test');
    const result = localStorage.getItem('localStorageEnabled');
    localStorage.removeItem('localStorageEnabled');
    return result === 'test';
  } catch {
    return false;
  }
}
