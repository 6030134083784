import { Injectable, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import {
  distinctUntilKeyChanged,
  filter,
  map,
  share,
  switchMap,
  take,
} from 'rxjs/operators';

import { permissionsData } from '../../constants/permissions-data';
import { AgencyEvent } from '../../models/agency-event.model';
import { AgencyModel } from '../../models/agency.model';
import { CompanyModel } from '../../models/company.model';
import { UserModel } from '../../models/user.model';
import { AgencyService } from '../agency/agency.service';
import { CompanyService } from '../company/company.service';
import { UsersService } from '../users/users.service';
import { DataAccessPolicy } from './data-access-policy';

@Injectable({
  providedIn: 'root',
})
export class CurrentContextService {
  private context: any = {};

  private agencySubject = new BehaviorSubject<AgencyModel>(null);
  private companySubject = new BehaviorSubject<CompanyModel>(null);
  private userSubject = new BehaviorSubject<UserModel>(null);

  public dataAccess: DataAccessPolicy;

  /**
   * This permission maps to an old concept: "can the user see support links".
   * This traditionally means that the user is either not part of a WL agency
   * or the user is part of a WL agency but has the permission levels needed
   * to "know" about callrail (manage billing, see support links, etc).
   * This combines agency state and user state and is populated by the
   * server to the user object for convenience.
   */
  public callRailAware$: Observable<boolean> = this.user.pipe(
    filter((u) => !!u),
    distinctUntilKeyChanged('support_links'),
    map((u) => u.support_links)
  );

  /**
   * Whether to show Lead Center Upsells
   */
  public showLCUpsell$: Observable<boolean> = combineLatest([
    this.agency,
    this.user,
  ]).pipe(
    map(([agency, user]) => {
      const hasLC =
        agency.hasFeature('contact_center') || agency.hasFeature('lead_center');
      const hasRC = agency.hasFeature('ring_central');
      const isAdmin = user.hasRole('admin');
      const notWhiteLabel = !agency.white_label;
      const notHIPAA = !agency.hipaa;
      return !hasLC && !hasRC && isAdmin && notWhiteLabel && notHIPAA;
    })
  );

  /**
   * Did the agency in context come through the "Lead with Lead Center" account creation?
   */
  public isLwlcPlan$ = this.agency.pipe(
    map((agency) => agency.lead_with_lead_center)
  );

  constructor(
    private agencyService: AgencyService,
    private companyService: CompanyService,
    private titleService: Title,
    private userService: UsersService,
    private zone: NgZone
  ) {
    this.initialize();
  }

  public initialize(context?: any) {
    this.context = context || this.getContextFromBodyOrWindow() || {};
    this.agencyService.agencyEvents.subscribe(
      this.agencyEventProcessorFactory()
    );

    this.initSubject('agency', AgencyModel, this.agencySubject);
    this.initSubject('company', CompanyModel, this.companySubject);
    this.initSubject('user', UserModel, this.userSubject);

    this.subscribeToCompanyService();

    this.updateCompanyOnAgencyChange();
    this.dataAccess = new DataAccessPolicy(this.callRailAware$);
  }

  public clear(property: string) {
    delete this.context['current_' + property];
  }

  public get(property: string) {
    return this.context['current_' + property];
  }

  public set(property: string, value: any) {
    this.context['current_' + property] = value;
  }

  public get env() {
    return this.context.env;
  }

  public get eagerFlippers() {
    return this.context.enabled_flippers;
  }

  public get ruby_constants() {
    return this.context.ruby_constants;
  }

  public get return_to_url() {
    return this.context.return_to_url;
  }

  public get sign_in_redirect() {
    return this.context.sign_in_redirect;
  }

  public get first_sign_in() {
    return this.context.first_sign_in;
  }

  public get current_user_companies(): CompanyModel[] {
    return this.context.current_user_companies; // not ALL of the companies. just first 300 active (simple decoration)
  }

  // #region Current Agency
  public get agency(): Observable<AgencyModel> {
    return this.agencySubject.asObservable();
  }

  /**
   * currentAgency is deprecated. Use a reactive-based approach with `agency` instead.
   * @deprecated
   */
  public get currentAgency(): AgencyModel {
    return this.agencySubject.getValue();
  }

  /* force the agency subject to be updated so subscriptions update for known agency state changes
   * i.e. changing the account plans
   */
  public reloadAgency(callback?: () => void): Observable<AgencyModel> {
    const updatedContext$ = this.agency.pipe(
      take(1),
      switchMap((agency) => this.setAgencyId(agency.id, callback)),
      share()
    );

    updatedContext$.subscribe();

    return updatedContext$;
  }

  public setAgencyId(
    id: string,
    callback?: () => void
  ): Observable<AgencyModel> {
    const updatedContext$ = this.agencyService.getUpdatedContext(id).pipe(
      map((agencyData) => new AgencyModel(agencyData)),
      share()
    );

    updatedContext$.subscribe((newAgency) => {
      this.agencySubject.next(newAgency);
      if (callback) {
        callback();
      }
    });

    return updatedContext$;
  }

  // #endregion

  // #region Current Company
  public get company(): Observable<CompanyModel> {
    return this.companySubject.asObservable();
  }

  /**
   * currentCompany is deprecated. Use a reactive-based approach with `company` instead.
   * @deprecated
   */
  public get currentCompany(): CompanyModel {
    return this.companySubject.getValue();
  }

  public reloadCompany() {
    this.setCompanyId(this.currentCompany.id, {});
  }

  public setCompanyId(
    id: string,
    options: { [key: string]: boolean } = {}
  ): Observable<CompanyModel> {
    const updatedCompany$ = this.companyService.get(id, options).pipe(
      map((companyData) => new CompanyModel(companyData)),
      share()
    );

    updatedCompany$.subscribe((newCompany) =>
      this.companySubject.next(newCompany)
    );

    return updatedCompany$;
  }

  public clearCompany(): void {
    this.companySubject.next(null);
  }

  /**
   * Deprecated. Use `isCurrentCompany$` instead.
   * @deprecated
   * @param companyId
   */
  public isCurrentCompany(companyId: string | number): boolean {
    // eslint-disable-next-line eqeqeq
    return this.currentCompany && this.currentCompany.id == companyId;
  }

  public isCurrentCompany$(companyId: string): Observable<boolean> {
    return this.company.pipe(map((company) => company.id === companyId));
  }
  // #endregion

  // #region Current User
  public get user(): Observable<UserModel> {
    return this.userSubject.asObservable();
  }

  /**
   * currentUser is deprecated. Use a reactive-based approach with `user` instead.
   * @deprecated
   */
  public get currentUser(): UserModel {
    return this.userSubject.getValue();
  }

  /* force the user subject to be updated
   */
  public reloadUser() {
    this.setUserId();
  }

  public setUser(user: UserModel) {
    this.userSubject.next(user);
  }

  // What agency is this context for? Used in Lead Center
  // LoadContextController.
  public setUserId(agencyId?: string): Observable<UserModel> {
    const updateUserContext$ = this.agency.pipe(
      take(1),
      switchMap((agency) => {
        const agency_id = agencyId || agency.id;
        return this.userService.current({ agency_id });
      }),
      map((userData) => new UserModel(userData)),
      share()
    );

    updateUserContext$.subscribe((newUser) => {
      this.setUser(newUser);
    });

    return updateUserContext$;
  }
  // #endregion

  // # region Permissions
  /**
   * Deprecated. Use `can$` instead.
   * @deprecated
   * @param action
   * @param subject
   * @param object
   * @param agency
   * @param user
   */
  public can(
    action: string,
    subject: string,
    object?: any,
    agency = this.currentAgency,
    user = this.currentUser
  ): boolean {
    if (action === 'access') {
      return agency.features.includes(subject);
    }

    if (
      permissionsData.permissions[user.role] &&
      permissionsData.permissions[user.role][subject] &&
      permissionsData.permissions[user.role][subject][action]
    ) {
      const actionPermission =
        permissionsData.permissions[user.role][subject][action];

      return typeof actionPermission === 'boolean'
        ? actionPermission
        : actionPermission(agency, object);
    }

    return false;
  }

  public can$(
    action: string,
    subject: string,
    object?: any
  ): Observable<boolean> {
    return combineLatest([this.agency, this.user]).pipe(
      map(([agency, user]) => {
        return this.can(action, subject, object, agency, user);
      })
    );
  }

  public setPageTitle(text) {
    const agencyName = this.currentAgency && this.currentAgency.titleName;
    const title = [text, agencyName].filter((item) => !!item).join(' | ');

    this.titleService.setTitle(title);
  }

  public hasFeatures(featureList: string[]): boolean {
    return featureList.every((el: string): boolean => {
      return this.can('access', el);
    });
  }

  public get usingTokenAuthentication(): boolean {
    return this.ruby_constants.user_token_login;
  }

  // #endregion

  private getContextFromBodyOrWindow() {
    let bodyContext;
    if (!!document.body.getAttribute('data-ng-current-context')) {
      bodyContext =
        JSON.parse(document.body.getAttribute('data-ng-current-context')) || {};
      document
        .getElementsByTagName('body')[0]
        .removeAttribute('data-ng-current-context');
      return bodyContext;
    } else if ((window as any).CallTrkUi) {
      const ctx = (window as any).CallTrkUi.ctx;
      (window as any).CallTrkUi.ctx = null;
      return ctx;
    }

    return null;
  }

  private initSubject(
    propName: string,
    modelClass: any,
    subject: BehaviorSubject<any>
  ) {
    const data = this.get(propName);
    if (data) {
      subject.next(new modelClass(data));
    } else {
      subject.next(null);
    }
  }

  private agencyEventProcessorFactory() {
    const agencySubject = this.agencySubject;
    return (event: AgencyEvent) => {
      if (
        agencySubject.value &&
        event.id === agencySubject.value.id &&
        event.verb === 'GET'
      ) {
        agencySubject.next(event.agency);
      }
    };
  }

  private subscribeToCompanyService(): void {
    if (
      !this.companyService.companyAdded$ ||
      !this.companyService.companyDisabled$
    ) {
      return;
    }

    this.companyService.companyAdded$.subscribe(
      (companyId: string | number) => {
        this.reloadAgency();
      }
    );

    this.companyService.companyDisabled$.subscribe(
      (companyId: string | number) => {
        // clear currentCompany if the company disabled was the current company
        if (this.isCurrentCompany(companyId)) {
          this.clearCompany();
        }
        this.reloadAgency();
      }
    );
  }

  private updateCompanyOnAgencyChange(): void {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      // start this subscription once Angular is stable, but don't hold up the constructor
      this.checkSingleActiveCompany();
    });
  }

  private checkSingleActiveCompany(): Subscription {
    return this.agency
      .pipe(
        filter((agency) => this.singleActiveCompany(agency)),
        distinctUntilKeyChanged('singleCompanyId'),
        switchMap((agency) => this.companyService.get(agency.singleCompanyId))
      )
      .subscribe((company) => {
        this.companySubject.next(new CompanyModel(company));
      });
  }

  private singleActiveCompany(agency): boolean {
    return (
      agency &&
      agency.singleCompanyId &&
      !this.isCurrentCompany(agency.singleCompanyId)
    );
  }
}
