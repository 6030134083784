<ng-container *ngIf="arraySegments; else stringAlert">
  <span *ngFor="let segment of arraySegments; first as isFirst; last as isLast">
    <span
      [class.u-font-weight-semibold]="segment.bold"
      *ngIf="segment.link || segment.function; else noLink">
      <a
        *ngIf="segment.link"
        [attr.target]="segment.targetBlank ? '_blank' : null"
        [href]="segment.link"
        >{{ segment.text }}</a
      >
      <a
        *ngIf="segment.function"
        (click)="segment.function ? segment.function() : null; close.emit()"
        >{{ segment.text }}</a
      >
    </span>

    <ng-template #noLink>
      <strong *ngIf="segment.bold">{{
        segment.text ? segment.text : segment
      }}</strong>
      <span *ngIf="!segment.bold">{{
        segment.text ? segment.text : segment
      }}</span>
    </ng-template>

    <ng-container *ngIf="!isLast">&nbsp;</ng-container>
  </span>
</ng-container>

<ng-template #stringAlert>
  {{ segments }}
</ng-template>
