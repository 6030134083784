import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  public fromString(text: string): any {
    if (!text.match(/\?/) || !text.match(/\?\w+/)) {
      return {};
    } // no `?` or nothing after `?`
    const params: object = {};

    for (let paramString of text.replace(/.*\?/, '').split('&')) {
      if (!paramString.match(/\=/)) {
        params[paramString] = null;
        continue;
      } // set val to null if no `=` present
      paramString = decodeURIComponent(paramString); // url-decode the string
      let paramKey = paramString.replace(/=.*/, ''); // paramKey is the `foo` in foo=bar
      let paramValue = paramString.replace(/.*=/, ''); // paramValue is the `bar` in foo=bar

      if (paramKey.match(/\[\]/)) {
        // if paramKey has an array key `foo[]`
        paramKey = paramKey.replace(/\[\]/, ''); // remove the `[]` from paramKey
        paramValue = params[paramKey]
          ? params[paramKey].concat([paramValue])
          : [paramValue];
      }
      params[paramKey] = paramValue;
    }
    return params;
  }

  public appendToString(text: string, params: object): string {
    if (!Object.keys(params).length) {
      return text;
    }
    text = this.appendQueryStringOperatorTo(text);

    for (const paramKey of Object.keys(params)) {
      const prepender: string = text.match(/\?$/) ? '' : '&'; // prepend '&' unless text ends with a '?'

      if (params[paramKey] instanceof Array) {
        if (params[paramKey].length > 0) {
          if (paramKey.match(/\[\]$/)) {
            text +=
              prepender +
              params[paramKey]
                .map((item) => `${paramKey}=${encodeURIComponent(item)}`)
                .join('&');
          } else {
            text +=
              prepender +
              params[paramKey]
                .map((item) => `${paramKey}[]=${encodeURIComponent(item)}`)
                .join('&');
          }
        }
      } else if (params[paramKey] === '') {
        text += `${prepender}${paramKey}=""`; // must append the "" manually
      } else if (!params[paramKey] && params[paramKey] !== 0) {
        text += `${prepender}${paramKey}`;
      } else {
        text += `${prepender}${paramKey}=${encodeURIComponent(
          params[paramKey]
        )}`;
      }
    }
    return text;
  }

  public filterByKeys(
    paramsToFilter: object,
    keysToFilterBy: string[]
  ): object {
    if (!keysToFilterBy.length) {
      return paramsToFilter;
    }
    const filteredParams: object = {};
    for (const filterKey of keysToFilterBy) {
      if (paramsToFilter[filterKey]) {
        filteredParams[filterKey] = paramsToFilter[filterKey];
      }
    }
    return filteredParams;
  }

  public removeKeys(paramsToFilter: object, keysToRemove: string[]): object {
    if (keysToRemove.length === 0) {
      return paramsToFilter;
    }
    const filteredParams: object = {};
    const paramsToKeep = Object.keys(paramsToFilter).filter(
      (key) => !keysToRemove.includes(key)
    );
    paramsToKeep.forEach((key) => (filteredParams[key] = paramsToFilter[key]));
    return filteredParams;
  }

  private appendQueryStringOperatorTo(path: string): string {
    return path.match(/\?/) ? path : path + '?';
  }
}
