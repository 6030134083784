export function getSafeUrl(url: string): string {
  if (url.startsWith('http') || url.startsWith('//')) {
    return url;
  }
  if (url.toLowerCase().startsWith('javascript')) {
    return '';
  }
  if (url.startsWith('/')) {
    // noop
  } else {
    url = '/' + url;
  }
  if (url.includes(location.origin)) {
    // noop
  } else {
    url = location.origin + url;
  }
  return new URL(url).href;
}
