import { Observable, Subject } from 'rxjs';

import { AgencyEvent } from '../../models/agency-event.model';
import { AgencyModel } from '../../models/agency.model';
import { Country } from '../../models/country.model';
import { AgencyService } from './agency.service';

class AgencyServiceMock extends AgencyService {
  public agencyEvents = new Subject<AgencyEvent>();
  private getObservable = new Observable<AgencyModel>();
  private putObservable = new Observable<AgencyModel>();
  private patchObservable = new Observable<AgencyModel>();
  private localesObservable = new Observable<AgencyModel>();
  private timezonesObservable = new Observable<AgencyModel>();
  private countriesObservable = new Observable<Country[]>();
  private toggleFeatureObservable = new Observable<null>();

  public get() {
    return this.getObservable;
  }
  public getUpdatedContext() {
    return this.getObservable;
  }
  public put() {
    return this.putObservable;
  }
  public patchById() {
    return this.patchObservable;
  }
  public patchModel() {
    return this.patchObservable;
  }
  public locales() {
    return this.localesObservable.toPromise();
  }
  public timezones() {
    return this.timezonesObservable;
  }
  public countries() {
    return this.countriesObservable;
  }
}

export const agencyServiceMock = new AgencyServiceMock(null);
