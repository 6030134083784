export const PARAM_AUTH_TOKENS = [
  'token',
  'ui_token',
  'access_key',
  'user_token',
  'verification_token',
];

export function isParamTokenAuth(ref: string): boolean {
  return PARAM_AUTH_TOKENS.some((token) => ref?.includes(token));
}
