/* This file lives outside of the Angular ecosystem and cannot rely on LocationService. */
/* eslint-disable local-rules/no-ng-location */
/* eslint-disable local-rules/no-window-location */

import { NgModule, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from '@aws-amplify/core';
import { CognitoUserPoolConfigService } from '@callrail/looky/common/core/shared';

const localDevelopmentHosts = ['callrail.test', 'localhost'];
const qaHosts = '-callrail.com';
const nonProductionHosts = [qaHosts, ...localDevelopmentHosts];

function defaultAmplifyAuthConfig(windowLocation: Location) {
  return {
    region: 'us-east-1',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    userPoolId: '',
    userPoolWebClientId: '',
    cookieStorage: {
      domain: windowLocation.hostname,
      secure: windowLocation.protocol === 'https:',
      sameSite: 'lax',
    },
    oauth: {
      domain: '',
      scopes: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: `${windowLocation.origin}/authenticate/`,
      redirectSignOut: `${windowLocation.origin}/authenticate/`,
      responseType: 'code',
    },
  };
}

function amplifyConfigure(
  userPoolId: string,
  clientId: string,
  oauthDomain: string,
  windowLocation: Location
) {
  const amplifyAuthConfig = defaultAmplifyAuthConfig(windowLocation);

  Amplify.configure({
    Auth: {
      ...amplifyAuthConfig,
      userPoolId,
      userPoolWebClientId: clientId,
      oauth: {
        ...amplifyAuthConfig.oauth,
        domain: oauthDomain,
      },
    },
  });
}

function bootstrapApp(appModule: Type<NgModule>) {
  platformBrowserDynamic()
    .bootstrapModule<NgModule>(appModule)
    /* eslint-disable-next-line no-console */
    .catch(console.error);
}

function useProduction(windowLocation: Location): boolean {
  return !nonProductionHosts.some((domain) =>
    windowLocation.hostname.includes(domain)
  );
}

function fetchTimeout(windowLocation: Location): number {
  // QA Environments - Wait 1 sec for a response before erroring and continuing with Production values when.
  // Local Development environments - Wait up to 1 minute for puma to start up.
  return windowLocation.hostname.includes(qaHosts) ? 1000 : 60000;
}

/* This file lives outside of the Angular ecosystem and cannot rely on LocationService. */
export function configureUserPool(
  appModule: Type<NgModule>,
  cognitoUserPoolConfig: CognitoUserPoolConfigService = new CognitoUserPoolConfigService(),
  windowLocation: Location = window.location
) {
  if (useProduction(windowLocation)) {
    cognitoUserPoolConfig.useDefaultProdUserPoolIds();
  } else {
    cognitoUserPoolConfig.useFetchedUserPoolIds(fetchTimeout(windowLocation));
  }

  cognitoUserPoolConfig.userPoolConfig$.subscribe({
    next: (config) => {
      if (config?.user_pool_id && config?.client_id && config?.oauth_domain) {
        amplifyConfigure(
          config.user_pool_id,
          config.client_id,
          config.oauth_domain,
          windowLocation
        );
      }
      bootstrapApp(appModule);
    },
    error: (error) => {
      /* eslint-disable-next-line no-console */
      console.log('configureUserPool/userPoolConfig$/error: ', error);
    },
  });
}
