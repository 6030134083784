import { Injectable } from '@angular/core';
import { AppInitLayoutService } from '@callrail/looky/core';
import { isEmpty } from 'lodash';
import { Observable, map } from 'rxjs';
import { ColorsService } from '../colors/colors.service';
import { WhiteLabelAttributes } from '../colors/white-label-attributes';

@Injectable({
  providedIn: 'root',
})
export class AnubisInitService {
  constructor(
    private readonly colorsService: ColorsService,
    private readonly appLayout: AppInitLayoutService
  ) {}

  public init(): Observable<WhiteLabelAttributes> {
    return this.colorsService.colors().pipe(
      map((wlAttrs) => {
        const isWhiteLabel = !isEmpty(wlAttrs);
        this.appLayout.setup();
        this.appLayout.addFavicon();
        this.appLayout.addStyles('anubis', isWhiteLabel, wlAttrs);
        return wlAttrs;
      })
    );
  }
}
