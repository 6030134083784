import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { SessionStorageService } from '@callrail/looky/util';

import { Alert } from '../../../models/alert.model';

const STORAGE_KEY = 'cross_app_alerts';

@Injectable({
  providedIn: 'root',
})
export class CrossAppAlertService {
  private readonly currentPathBase: string;

  constructor(
    private sessionService: SessionStorageService,
    @Optional() @Inject(APP_BASE_HREF) private appBaseHref?: string // `/lead-center/`
  ) {
    // given an input baseHref like "/lead-center/a/123",
    // this extracts the "app-defining" part of the path out. End result is "/lead-center"
    this.currentPathBase = this.appBaseHref
      ? this.appBaseHref.match(/\/[^\/]+\//)[0]
      : '/';
  }

  /**
   * When a new alert is sent to AlertFlashService, should that alert be shown right away, or stored
   * and shown later?
   * @param alert the alert in question
   * @returns true if the alert is good to be shown right away. False if the alert should be put in storage
   */
  public shouldStoreNewAlert(alert: Alert): boolean {
    if (alert.crossApp) {
      // if app isn't passed in, then it's assumed that we never want to immediately show the alert
      if (!alert.app) return true;
      return !this.alertShouldBeShown(alert);
    } else {
      // Never store alerts that aren't cross-app alerts
      return false;
    }
  }

  /**
   * Stores an alert in a place where we can later retrieve it
   * @param alert The alert to be stored
   */
  public storeAlert(alert: Alert): void {
    const alerts = this.getAlertsFromStorage();
    alerts.push(alert);
    this.setAlertsInStorage(alerts);
  }

  /**
   * Returns an array of alerts that should be shown
   */
  public pullAlertsToShowFromStorage(): Alert[] {
    const alerts = this.getAlertsFromStorage();

    const output = alerts.filter((alert) => this.alertShouldBeShown(alert));
    const skipped = alerts.filter((alert) => !this.alertShouldBeShown(alert));
    this.setAlertsInStorage(skipped);

    return output;
  }

  /**
   * Determines if an alert is able to be shown in the current app
   * @param alert the alert in question
   * @returns whether or not the alert should be shown in the app, (`true` means the alert can be shown)
   */
  private alertShouldBeShown(alert: Alert): boolean {
    if (!alert.app) {
      return true;
    }
    // both alert.app and currentPathBase should be in the form "/text/" so it's safe to do exact match
    return alert.app === this.currentPathBase;
  }

  private getAlertsFromStorage(): Alert[] {
    return this.sessionService.getItem(STORAGE_KEY) || [];
  }

  private setAlertsInStorage(alerts: Alert[]) {
    this.sessionService.setItem(STORAGE_KEY, alerts);
  }
}
