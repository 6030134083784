import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmplifyFormComponent } from './components/amplify-form/amplify-form.component';
import { AmplifyRedirectComponent } from './components/amplify-redirect/amplify-redirect.component';
import { SSOComponent } from './components/sso/sso.component';
import { AmplifySignoutComponent } from './components/amplify-signout/amplify-signout.component';

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AmplifyFormComponent,
    AmplifyRedirectComponent,
    AmplifySignoutComponent,
    SSOComponent,
  ],
  imports: [
    CommonModule,
    AmplifyAuthenticatorModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AmplifyFormComponent,
    AmplifyRedirectComponent,
    AmplifySignoutComponent,
    SSOComponent,
  ],
})
export class AnubisLoginModule {}
