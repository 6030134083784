import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

const defaultDuration = '200ms';
/**
 * Include the `appAnimations` const into a component declaration, such as:
 *
 * ```ts
 *   Component({
 *     selector: 'ui-example-component',
 *     templateUrl: 'example.component.html',
 *     appAnimations
 *   })
 * ```
 *
 * To use, find an animation `trigger` in this constant, and include it as a property on an element in your HTML
 * template file.
 *
 * Once the variable "showButton" is true,
 * this button will appear on the page with the same animation that dropdown menus use.
 *
 * ```html
 *   <div *ngIf="showButton" [@dropAppearAnimation]="'down'" class="btn">
 *     Button Example
 *   </div>
 * ```
 *
 * Available Animation Types (triggers):
 * - `@crAnimation` (generic animations)
 * - `@dropAppearAnimation`
 * - `@sidebarAnimation`
 * - `@listAnimation`
 * - `@tooltip`
 */
export const appAnimations = [
  trigger('crAnimation', [
    transition('void => fade', [
      style({
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
        })
      ),
    ]),
    transition('fade => void', [
      style({
        opacity: 1,
      }),
      animate(
        defaultDuration,
        style({
          opacity: 0,
        })
      ),
    ]),
  ]),

  trigger('dropAppearAnimation', [
    transition('void => down', [
      style({
        transform: 'translate3d(0, -10px, 0) scale(0.97)',
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(0, 0, 0) scale(1)',
          opacity: 1,
        })
      ),
    ]),
    transition('down => void', [
      style({
        transform: 'translate3d(0, 0, 0) scale(1)',
        opacity: 1,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(0, -10px, 0) scale(0.97)',
          opacity: 0,
        })
      ),
    ]),
    transition('void => up', [
      style({
        transform: 'translate3d(0, 10px, 0) scale(0.97)',
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(0, 0, 0) scale(1)',
          opacity: 1,
        })
      ),
    ]),
    transition('up => void', [
      style({
        transform: 'translate3d(0, 0, 0) scale(1)',
        opacity: 1,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(0, 10px, 0) scale(0.97)',
          opacity: 0,
        })
      ),
    ]),
  ]),

  trigger('sidebarAnimation', [
    transition('void => right', [
      style({
        transform: 'translate3d(-20px, 0, 0)',
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        })
      ),
    ]),
    transition('right => void', [
      style({
        transform: 'translate3d(0, 0, 0)',
        opacity: 1,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(-20px, 0, 0)',
          opacity: 0,
        })
      ),
    ]),
    transition('void => fade', [
      style({
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
        })
      ),
    ]),
    transition('fade => void', [
      style({
        opacity: 1,
      }),
      animate(
        defaultDuration,
        style({
          opacity: 0,
        })
      ),
    ]),
  ]),

  trigger('lcLeftPanel', [
    transition('void => right', [
      style({
        transform: 'translate3d(-20px, 0, 0)',
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        })
      ),
    ]),
    transition('right => void', [
      style({
        transform: 'translate3d(0, 0, 0)',
        opacity: 1,
      }),
      animate(
        0,
        style({
          transform: 'translate3d(-20px, 0, 0)',
          opacity: 0,
        })
      ),
    ]),
    transition('void => fade', [
      style({
        opacity: 0,
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
        })
      ),
    ]),
    transition('fade => void', [
      style({
        opacity: 1,
      }),
      animate(
        0,
        style({
          opacity: 0,
        })
      ),
    ]),
  ]),

  trigger('listAnimation', [
    transition('* => *', [
      // each time the binding value changes
      query(
        ':leave',
        [
          stagger(50, [
            animate(
              defaultDuration,
              style({
                transform: 'translate3d(0, 0, 0)',
                height: 0,
                'padding-top': 0,
                'padding-bottom': 0,
                opacity: 0,
              })
            ),
          ]),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            transform: 'translate3d(-20px, 0, 0)',
            opacity: 0,
          }),
          stagger(50, [
            animate(
              defaultDuration,
              style({
                transform: 'translate3d(0, 0, 0)',
                opacity: 1,
              })
            ),
          ]),
        ],
        { optional: true }
      ),
    ]),
  ]),

  trigger('tooltip', [
    transition('void => top, none => top', [
      style({
        opacity: 0,
        transform: 'translate3d(0, 3px, 0)',
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        })
      ),
    ]),
    transition('top => void', [
      animate(
        defaultDuration,
        style({
          opacity: 0,
          transform: 'translate3d(0, 3px, 0)',
        })
      ),
    ]),
    transition('void => bottom, none => bottom', [
      style({
        opacity: 0,
        transform: 'translate3d(0, -3px, 0)',
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        })
      ),
    ]),
    transition('bottom => void', [
      animate(
        defaultDuration,
        style({
          opacity: 0,
          transform: 'translate3d(0, -3px, 0)',
        })
      ),
    ]),
    transition('void => right, none => right', [
      style({
        opacity: 0,
        transform: 'translate3d(-3px, 0, 0)',
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        })
      ),
    ]),
    transition('right => void', [
      animate(
        defaultDuration,
        style({
          opacity: 0,
          transform: 'translate3d(-3px, 0, 0)',
        })
      ),
    ]),
    transition('void => left, none => left', [
      style({
        opacity: 0,
        transform: 'translate3d(3px, 0, 0)',
      }),
      animate(
        defaultDuration,
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        })
      ),
    ]),
    transition('left => void', [
      animate(
        defaultDuration,
        style({
          opacity: 0,
          transform: 'translate3d(3px, 0, 0)',
        })
      ),
    ]),
    transition(':leave', [animate(defaultDuration, style({ opacity: 0 }))]),
  ]),
];
