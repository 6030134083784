import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LocationService } from '@callrail/looky/util';
import { AssetsService } from '@callrail/looky/ui';

@Component({
  selector: 'anbs-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['sso.component.scss'],
})
export class SSOComponent {
  public emailInput = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private locationService: LocationService,
    public assetsService: AssetsService
  ) {}

  public singleSignOn() {
    if (this.emailInput.valid) {
      const emailDomain = this.emailInput.value.split('@')[1];
      let url = `https://${process.env.COGNITO_DOMAIN}/authorize`;
      url += `?response_type=code&client_id=${process.env.COGNITO_CLIENT_ID}`;
      url += `&idp_identifier=${emailDomain}`;
      url += `&redirect_uri=${process.env.COGNITO_REDIRECT_SIGN_IN}`;
      this.locationService.go(url, true);
    }
  }
}
