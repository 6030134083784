import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HoneyBadgerServiceMock {
  public hb = {
    afterNotify: () => ({}),
    configure: () => ({}),
    beforeNotify: () => ({}),
    notify: () => ({}),
    addBreadcrumb: () => ({}),
    resetMaxErrors: () => ({}),
  };
}
