import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProgressiveDataRes } from '@callrail/inti/src/inti/proto/progressive_data';
import _ from 'lodash';
import { ProgressiveDataXformer } from '../legacy-transformers/progressive-data-xformer';

import { CurrentContextService } from '../../current-context/current-context.service';

@Injectable({
  providedIn: 'root',
})
export class ProgressiveDataService {
  private readonly intiBffVersion = 'v1';

  constructor(
    private http: HttpClient,
    private context: CurrentContextService
  ) {}

  /*
  This method can be used to fetch additional data from Inti for an integration.
  To use, add to your integration component:

  import { ProgressiveDataService } from '@callrail/looky/core';
  ...
  private get progressiveDataService() { return this.injector.get(ProgressiveDataService); }
  ...
  // define the payload, if you need to include any additional data in the request:
  const payload = {
    vendor_user_id: "USER123"
  }
  // set the `vendor_name_action` to match the name of your vendor + the action name defined in the protobuf
  this.progressiveDataService.fetch(this.integration.id, "vendor_name_action", payload).subscribe((result) => {
    // add your code here to handle the result
  })
  */
  public fetch(
    integrationId: number,
    action: string,
    payload: any
  ): Observable<any> {
    const companyMid = this.context.currentCompany.id;
    const xformer = new ProgressiveDataXformer(action, integrationId, payload);
    const reqBody = xformer.toReqProtoBase64();
    const url = `/integration-settings/${this.intiBffVersion}/c/${companyMid}/progressive_data`;

    return this.http.post(url, reqBody, { responseType: 'arraybuffer' }).pipe(
      map((intiRes) => {
        const uint8 = new Uint8Array(intiRes);
        const deserialized = ProgressiveDataRes.decode(uint8);
        const resType = `${_.camelCase(action)}Res`;
        return deserialized[resType];
      })
    );
  }
}
