/* eslint-disable no-underscore-dangle */
import { dateFormat } from '@callrail/looky/ui';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Observable } from 'rxjs';
import { dataAccessErrorMessages } from '../../constants/data-access-error-messages';
import { DataAccessErrorMessages } from '../../interfaces/data-access-policy';

export class DataAccessPolicy {
  private dayjs = dayjs();
  private canViewSupportLinks: boolean;

  constructor(private callRailAware$: Observable<boolean>) {
    dayjs.extend(isSameOrAfter);
    this.callRailAware$.subscribe(
      (canViewSupportLinks) => (this.canViewSupportLinks = canViewSupportLinks)
    );
  }

  /**
   * The data retention limit policy limits the user to
   * two years of reporting data (which we define to 25 months).
   */
  private _minReportingDate = this.dayjs.subtract(25, 'months');

  /**
   * Returns the earliest reporting date in string format
   * @returns {string} 'YYYY-MM-DD'
   */
  public get minReportingDate(): string {
    return this._minReportingDate.format(dateFormat);
  }

  /**
   * Check whether the date is within the minimum allowed
   * reporting date range
   * @param {string} date - A date string in the format 'YYYY-MM-DD'
   * @param {dayjs.Moment} date - A Moment.js date object
   */
  public isWithinReportingDateRange(date: string | dayjs.Dayjs): boolean {
    const checkDate = typeof date === 'string' ? dayjs(date, dateFormat) : date;
    return checkDate.isSameOrAfter(this._minReportingDate, 'day');
  }

  /**
   * Check whether the date is within the months allowed in the
   * data retention policy. This differs from the `isWithinReportingDateRange`
   * method in that it does not compare the day.
   */
  public isWithinDataAccessRange(date: string | dayjs.Dayjs): boolean {
    const checkDate = typeof date === 'string' ? dayjs(date, dateFormat) : date;
    return checkDate.isSameOrAfter(this._minReportingDate.startOf('month'));
  }

  /**
   * Error message to display to the user when the selected date
   * does not fall within the the data retention period.
   */
  public dataAccessErrorMessage(location: DataAccessErrorMessages = 'default') {
    if (
      !this.canViewSupportLinks &&
      dataAccessErrorMessages[location].whitelabel
    ) {
      return dataAccessErrorMessages[location].whitelabel;
    } else {
      return dataAccessErrorMessages[location].default;
    }
  }
}
