import { Injectable } from '@angular/core';
import { Context } from '../../models/context.model';

@Injectable({
  providedIn: 'root',
})
export class DatadogRumServiceMock {
  public initialize(_context: Context, _app: string): void {
    // do nothing since this is a mock
  }
  public addError(_error, _metadata?: any): void {
    // do nothing since this is a mock
  }
}
