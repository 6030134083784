import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationService } from '@callrail/looky/util';
import { catchError, Observable, throwError } from 'rxjs';

import { HeaderTokenAuthTokenResponse } from '../../interfaces/header-token-auth-token-response';

@Injectable({
  providedIn: 'root',
})
export class RingCentralIframeService {
  private readonly sessionTokenUuidKey = 'rcstuuid';
  private readonly agencyIdKey = 'rcacid';

  constructor(
    private http: HttpClient,
    private locationService: LocationService
  ) {}

  public tokenExchange(
    path: string
  ): Observable<HeaderTokenAuthTokenResponse | HttpErrorResponse> {
    if (!this.sessionTokenUuid) {
      return this.handleError(new HttpErrorResponse({ status: 401 }));
    }
    return this.http
      .post<HeaderTokenAuthTokenResponse>(path, { uuid: this.sessionTokenUuid })
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public get signOut(): Observable<unknown | HttpErrorResponse> {
    this.sessionTokenUuid = '';
    this.agencyId = '';
    return this.http.delete<HeaderTokenAuthTokenResponse>(
      '/ring-central/sign-out-token-session'
    );
  }

  public get sessionTokenUuid(): string {
    return localStorage.getItem(this.sessionTokenUuidKey);
  }

  public set sessionTokenUuid(uuid: string) {
    localStorage.setItem(this.sessionTokenUuidKey, uuid);
  }

  public get agencyId(): string {
    return localStorage.getItem(this.agencyIdKey);
  }

  public set agencyId(id: string) {
    localStorage.setItem(this.agencyIdKey, id);
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.sessionTokenUuid = ''; // in case err isnt 401 and we never make it back to popup to clear/reset this
    // if the BE were to respond with a non 401 status code and we were to redirect them to the the same /waiting
    // screen we send them to for 401 errors to refresh their session_token, we could end up putting them into an
    // infinite loop where as soon as we automatically open the popup on the waiting screen and they then get
    // redirected back to the /ring-central-home screen that makes this tokenExchange request (again).
    if (error.status === 401) {
      this.locationService.go(
        `/authy/session/providers/call_rail/waiting`,
        true
      );
    }
    return throwError(() => error);
  }
}
