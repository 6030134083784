import { Injectable } from '@angular/core';
import { LocationService } from '@callrail/looky/util';
import { CognitoIdToken } from 'amazon-cognito-identity-js';

import { map, Observable, take } from 'rxjs';
import { CognitoTokenService } from '@callrail/looky/common/core/shared';
import { SimpleAgency } from '../../interfaces/simple-agency';

@Injectable({ providedIn: 'root' })
export class MFAMigrationStateService {
  migrationAgencyKey = 'migrationAgencyId';

  constructor(
    private cognitoTokenService: CognitoTokenService,
    private locationService: LocationService
  ) {}

  public isUserInMigrationState(idToken: CognitoIdToken): boolean {
    if (idToken?.payload?.['custom:Status']) {
      return idToken.payload['custom:Status'] === 'mfa_migration';
    }
    return false;
  }

  public forceMfaMigration(): Observable<boolean> {
    return this.cognitoTokenService.idToken$.pipe(
      take(1),
      map((idToken) => this.isUserInMigrationState(idToken))
    );
  }

  public redirectToAccountPicker(): void {
    this.locationService.go(
      `${this.locationService.origin}/account-center/choose-account?redirect_to=/settings/mfa-migration`,
      true
    );
  }
}
