/** @cabooseInclude */

import { Inject, Injectable, Optional } from '@angular/core';

import { store } from './store';
interface Store {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private store: Store;
  private host: string;

  constructor(
    @Optional() @Inject('env') private env?: any, // from looky apps
    @Optional() @Inject('railsEnv') private railsEnv?: any, // from callrail apps
    @Optional() @Inject('assetsStore') private assetsStore?: Store // for stub testing
  ) {
    this.env = this.env || {};
    this.railsEnv = this.railsEnv || {};
    this.host = this.hostStr;
    this.store = this.storeObj;
  }

  public resolve(key: string): string {
    if (this.store) {
      // set during ci-cd process for staging|prod
      return this.store[key];
    } else {
      // development
      return this.host + key;
    }
  }

  private get storeObj(): Store {
    const _store = this.assetsStore || store;
    if (Object.keys(_store).length) {
      const assets = {};
      for (const key of Object.keys(_store)) {
        assets[key] = `${this.host}${_store[key]}`;
      }
      return assets;
    }
  }

  private get hostStr(): string {
    let str;
    if (this.railsEnv.development) {
      str = this.railsEnv.devAssetsPath; // cr apps in development.
    } else if (this.env.development) {
      // looky apps in development
      // this works for all looky apps in development because we are copying over all ui/assets into
      // the respective looky app/assets folder verbatim. In production/staging we use the ui/assets s3 urls
      str = 'assets/';
    } else if (this.env.staging || this.railsEnv.staging) {
      str = '//assets.staging-callrail.com/packs/ui/assets/';
    } else {
      // production
      str = '//assets.calltrk.com/packs/ui/assets/';
    }
    return str;
  }
}
