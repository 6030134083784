import { AppRoutes } from '@callrail/looky/util';

import { AlertID } from '../type-aliases/alert-id';

export class Alert {
  public id?: AlertID;
  public text?: string;
  public segments?: AlertSegment[];
  public type: AlertType;
  public linkText?: string;
  public linkFunction?: any;
  public showAfterRouteChange?: boolean;
  public timeout?: number; // number of seconds before alert auto-dismisses
  public callback?: () => any;
  /**
   * Set this if there is a specific app that you want to show this alert in.
   * Leaving this blank will show the alert after ANY app change
   *
   * Example uses:
   *
   * **Scenario 1:** You're on a page in Settings and you're about to redirect to a page in Analytics.
   * You could either leave this blank, or set it to AppRoutes.ANALYTICS.
   * Both options will show your alert after redirect.
   *
   * **Scenario 2:** You're on a page in settings, and you might redirect to either Analytics or Lead Center.
   * It would be easiest to leave this blank. That would show the alert after you leave the Settings app.
   *
   * **Scenario 3:** You're using this in a component that is used in both the Analytics and Settings apps,
   * and you plan to use a cross_app_link to go to the Settings app.
   * You should set this value to AppRoutes.SETTINGS, which will immediately use the alert-flash-service if
   * you're already in the Settings app. Or will wait until you're in the Settings app once you come from Analytics.
   */
  public app?: AppRoutes;
  /**
   * If true, then the alert will be treated like a cross-app alert.
   *
   * It's not enough to not set `app`, since cross-app alerts with no `app` property behave in their own way.
   * See documentation on the `app` property for more information.
   */
  public crossApp?: boolean;
  constructor(alertData: Alert) {
    Object.assign(this, alertData);
  }
}

export type AlertSegment = {
  text: string;
  link?: string;
  bold?: boolean;
  targetBlank?: boolean;
  function?: any;
};

export type AlertType = 'success' | 'danger' | 'warning' | 'info';
