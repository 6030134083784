<amplify-authenticator
  [loginMechanisms]="['email']"
  [socialProviders]="socialProviders"
  [hideSignUp]="true"
  [formFields]="formFields"
  [initialState]="initialState">
  <!-- Add Redirect Component -->
  <ng-template amplifySlot="authenticated">
    <anbs-amplify-redirect
      [redirectType]="redirectType"
      [remember]="remember"></anbs-amplify-redirect>
  </ng-template>
  <!-- Add Redirect Component -->

  <!-- Update footer links for sign in -->
  <ng-template amplifySlot="sign-in-footer">
    <!-- Add Remember Me checkbox -->
    <ng-container *ngIf="redirectType === 'auth'">
      <!-- <div class="remember">
      <label for="remember">
        <input id="remember" type="checkbox" (click)="toggleRemember()"> Remember Me
      </label>
    </div> -->
    </ng-container>

    <div class="sign-in-footer">
      <button
        (click)="authenticator.toResetPassword()"
        class="btn btn--tertiary reset-password"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        type="button">
        Forgot my password
      </button>
      <br />

      <ng-container *ngIf="showFreeTrial">
        <a href="https://www.callrail.com/pricing/">Get a free trial</a>
      </ng-container>
    </div>
  </ng-template>
  <!-- Update footer links for sign in -->

  <!-- Empty ng-template to clear out default heading -->
  <ng-template amplifySlot="reset-password-header">
    <h1 class="reset-password-heading">Need to reset your password?</h1>
    <p class="reset-password-description">
      Please enter your email and hit
      <span class="description-action">Send code</span>. A verification code
      will be sent to the email indicated.
    </p>
  </ng-template>

  <!-- Confirm reset password header -->
  <ng-template amplifySlot="confirm-reset-password-header">
    <h1 class="confirm-reset-password-heading">Set your new password</h1>
  </ng-template>
</amplify-authenticator>
