import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { WhiteLabelAttributes } from './white-label-attributes';

@Injectable({
  providedIn: 'root',
})
export class ColorsService {
  private readonly colorsSubject = new ReplaySubject<WhiteLabelAttributes>(1);
  public colors$ = this.colorsSubject.asObservable();

  constructor(private readonly http: HttpClient) {}

  /*
   * Fetches the white label attributes from the server.
   * Called from the AnubisAppInitService. If you need
   * colors, please use +colors$+ instead.
   * @returns {Observable<WhiteLabelAttributes>}
   */
  public colors(): Observable<WhiteLabelAttributes> {
    return this.http.get<WhiteLabelAttributes>('/colors').pipe(
      tap((wlAttrs) => {
        this.colorsSubject.next(wlAttrs);
      })
    );
  }
}
