import { Inject, Injectable, Optional } from '@angular/core';
import { DocumentRefService } from '@callrail/looky/util';
import { from, Observable, of, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ErrorReporterService } from '../error-reporter/error-reporter.service';

declare const Sfdc: any; // salesforce canvas js sdk

interface SignedRequestResponse {
  status: number;
  payload: { response: string };
  statusText: string[];
}

interface CanvasClient {
  refreshSignedRequest: (
    callback: (resp: SignedRequestResponse) => void
  ) => void;
  repost: (bool: boolean) => void;
}

@Injectable({
  providedIn: 'root',
})
export class SalesforceCanvasService {
  private sdkLoadRetries = 0;

  constructor(
    private documentRefService: DocumentRefService,
    private errorReporterService: ErrorReporterService,
    @Optional() @Inject('env') private env?: any,
    @Optional() @Inject('sfdc') private sfdc?: any
  ) {}

  public get client(): CanvasClient {
    if (this.env?.development) {
      return this.stubbedClient;
    }
    return (this.sfdc || Sfdc).canvas.client;
  }

  public appendScriptToBody(): void {
    const script = this.documentRefService
      .nativeDocument()
      .createElement('script');
    // this file was downloaded directly from https://login.salesforce.com/canvas/sdk/js/54.0/canvas-all.js
    // and then minified with an online minifier https://www.toptal.com/developers/javascript-minifier/
    // and then uploaded to our cdn following these instructions https://callrail.atlassian.net/l/c/SvRVYswq
    script.src =
      'https://s3.amazonaws.com/public-assets.calltrk.com/web-assets/salesforce/v54.0/canvas-all.min.js';
    script.type = 'text/javascript';
    this.documentRefService.nativeDocument().body.appendChild(script);
  }

  public get refreshSignedRequest(): Observable<string> {
    return this.waitForClient.pipe(
      switchMap((client) => {
        return from(
          new Promise<string>((resolve, reject) => {
            try {
              client.refreshSignedRequest((resp: SignedRequestResponse) => {
                if (resp.status === 200) {
                  resolve(resp.payload.response);
                } else {
                  const error = new Error(resp.statusText[0]);
                  this.errorReporterService.report(this.errorWithName(error), {
                    caught: true,
                  });
                  reject(resp.statusText[0]);
                }
              });
            } catch (error) {
              const initialCode = error.status;
              error.status = 500; // ensure we _do_ actually badger this no matter what code it had initially
              this.errorReporterService.report(this.errorWithName(error), {
                uncaught: true,
                code: initialCode,
              });
              reject(error.message);
            }
          })
        );
      })
    );
  }

  // this function is necessary because the sdk script is being added to the index.html after page load.
  // We need to wait for the sdk javascript to have run so the global `Sfdc` object is available to use
  private get waitForClient(): Observable<CanvasClient> {
    try {
      return of(this.client);
    } catch (error) {
      return of({}).pipe(
        delay(250),
        switchMap(() => {
          if (this.sdkLoadRetries > 10) {
            return throwError(error); // Sfdc not defined
          } else {
            this.sdkLoadRetries += 1;
            return this.waitForClient;
          }
        })
      );
    }
  }

  private get stubbedClient(): CanvasClient {
    return {
      refreshSignedRequest: (callback) => {
        callback({
          status: 200,
          payload: { response: 'stub' },
          statusText: [],
        });
      },
      repost: (bool) => {
        console.warn(`stubbed repost(${bool})`); // eslint-disable-line
      },
    };
  }

  private errorWithName(error: Error): Error {
    // for easier scanning in Honeybadger UI and team/individual assignment
    error.name = `SalesforceCanvasService::${error.name}`;
    return error;
  }
}
