import { activeAgencyStates } from '../constants/active-agency-states';
import { PlanTier as Tier } from '../interfaces/plan-tier';
import { PoolSizeControl } from '../interfaces/pool-size-control';
import { AgencyStates } from '../type-aliases/agency-states';
import { ClientViewAccessesModel } from './client-view-accesses.model';
import { ProductType } from '../type-aliases/product-type';

interface RatePlan {
  name: string;
  platform: ProductType;
}

export class AgencyModel implements PoolSizeControl {
  public id: string;
  public resource_id?: string;
  public name: string;
  public subaccount_id?: string;
  public lead_center_first?: boolean;
  public active_company_count?: number;
  public active_tracker_count?: number;
  public admin_count?: number;
  public billing_config_enabled?: boolean;
  public call_transcription_unit_charge?: string;
  public call_waiting_availability?: boolean;
  public can_enable_billing_by_parent?: boolean;
  public can_screen?: boolean;
  public created_at?: string;
  public current_plan?: string;
  public current_users_role?: any;
  public is_plan_international?: boolean;
  public days_left_in_trial?: number;
  public agency_domain?: string;
  public features?: string[];
  public form_capture?: boolean;
  public hub_spot_legacy?: boolean;
  public hipaa?: boolean;
  public intro_completed?: boolean;
  public intro_step_completed?: string;
  public keyword_pool_size_max: number;
  public keyword_spotting_enabled?: boolean; // https://callrail.atlassian.net/browse/CR-29176
  public automation_rules_enabled?: boolean;
  public lead_with_lead_center?: boolean;
  public homey_enabled?: boolean;
  public max_phone_numbers: number;
  public max_session_age?: number;
  public multiple_keyword_pools?: boolean;
  public last_invoice?: string;
  public lead_with_lead_center_pending_user_count?: number;
  public next_invoice?: string;
  public number_count: number;
  public active_number_count?: number;
  public outbound_greeting_enabled?: boolean;
  public outbound_recording_enabled?: boolean;
  public outbound_recording_on_by_default?: boolean;
  public referral_key?: string;
  public session_tracker_min_size: number;
  public stripe_integration?: boolean;
  public toll_free_numbers_charge?: number;
  public tracker_count?: number;
  public tracking_numbers?: any;
  public trial: boolean;
  public trial_expired?: boolean;
  public has_current_trials?: boolean;
  public upsell_plan?: any;
  public user_count?: number;
  public white_label?: boolean;
  public white_label_attributes?: any;
  public color_background?: string;
  public header_icon_color?: string;
  public header_logo?: any;
  public login_logo?: any;
  public email_logo?: any;
  public show_full_comp_name_on_invoice?: boolean;
  public support_email?: string;
  public state?: AgencyStates;
  public plan_tier?: Tier;
  public has_cc: boolean;
  public added_cc_at: string;
  public calls_enabled?: boolean;
  public forms_enabled?: boolean;
  public first_company_id?: string;
  public active_native_forms: number;
  public active_chat_configs_count: number;
  public has_form_captures: boolean;
  public max_free_forms: number;
  public free_form_capture_count: number;
  public free_form_capture_limit: number;
  public free_form_capture_reset: string;
  public paid_form_capture_count: number;
  public paid_form_capture_limit: number;
  public billable_customer: boolean;
  public form_capture_additional_usage: number;
  public disable_for_nonpayment_on: string;
  public has_zuora_account: boolean;
  public disabled_numbers_at?: string;
  public rate_plans: RatePlan[];
  public platforms: string[];
  public account_type: string;
  public paused?: boolean;
  public paused_on?: string;
  public ac_account?: boolean;
  public porting_pin?: number;
  public account_state?: string;
  public multi_product: boolean;
  public sms_never_expire?: boolean;
  public manually_invoiced?: boolean;
  public multiple_swap_targets?: boolean;
  public time_zone_name?: string;
  public time_zone_moment?: any;
  public time_zone_display?: string;
  public sms_details?: {
    allowed_limit: number;
    cold_outbound_limit: number;
    errors: any[];
  };
  public client_view_accesses: ClientViewAccessesModel;
  public brand_status: string;
  public has_unique_subaccount: string;
  public has_lead_center_trial: boolean;
  public billing_by_parent: boolean;

  constructor(fromData?: object) {
    Object.assign(this, fromData);
    this.multi_product =
      [
        this.calls_enabled,
        this.forms_enabled,
        this.hasFeature('lc_chat'),
      ].filter((x) => x === true).length > 1;
    // https://callrail.atlassian.net/browse/CR-29176
    this.automation_rules_enabled =
      this.automation_rules_enabled || this.keyword_spotting_enabled;
  }

  public static isActive(state): boolean {
    return activeAgencyStates.includes(state);
  }

  public static getRoute(id: string): string {
    return `/a/${id}`;
  }

  public get route(): string {
    return AgencyModel.getRoute(this.id);
  }

  public get internationalDial(): boolean {
    return this.features.indexOf('international_calling') > -1;
  }

  public get siteName(): string {
    if (this.white_label) {
      return this.white_label_attributes.white_label_site_name;
    } else {
      return 'CallRail';
    }
  }

  public get hasWhiteLabelLogo(): boolean {
    if (this.whiteLabelLogo || this.legacyLogo) {
      return true;
    }
  }

  public get whiteLabelLogoUrl(): string {
    if (this.white_label && this.hasWhiteLabelLogo) {
      return this.whiteLabelLogo || this.legacyLogo;
    } else {
      return null;
    }
  }

  public get whiteLabelBodyClasses(): { [key: string]: boolean } {
    if (this.white_label) {
      const colorLuminanceClass =
        this.white_label_attributes.white_label_color_luminance_class;

      return {
        'white-label': true,
        [colorLuminanceClass]: !!colorLuminanceClass,
      };
    }
    return null;
  }

  public get titleName(): string {
    if (this.white_label) {
      return `${this.siteName} Call Tracking`;
    } else {
      return this.siteName;
    }
  }

  public get active(): boolean {
    return activeAgencyStates.includes(this.state);
  }

  public get pending(): boolean {
    return this.state === 'pending';
  }

  public hasFeature(feature_id: string): boolean {
    if (feature_id == null) {
      return true;
    }
    if (!this.features || !this.features.length) {
      return false;
    }
    return this.features.includes(feature_id);
  }

  public hasProductType(productType: string): boolean {
    if (productType == null) {
      return true;
    }
    return this[productType] === true;
  }

  public get callsOnly(): boolean {
    return this.calls_enabled === true && !this.multi_product;
  }

  public get callsOnlyNoSms(): boolean {
    return this.callsOnly && !this.hasFeature('text_messaging');
  }

  public get formsOnly(): boolean {
    return this.forms_enabled === true && !this.multi_product;
  }

  public get freeForms(): boolean {
    return this.forms_enabled === true && !this.hasFeature('paid_forms');
  }

  public get freeFormsOnly(): boolean {
    return this.formsOnly && !this.hasFeature('paid_forms');
  }

  public get paidFormsOnly(): boolean {
    return this.formsOnly && this.hasFeature('paid_forms');
  }

  public get paidForms(): boolean {
    return this.forms_enabled === true && this.hasFeature('paid_forms');
  }

  public get singleActiveCompany(): boolean {
    return this.active_company_count === 1;
  }

  public get singleCompanyId(): string {
    if (this.singleActiveCompany && this.first_company_id) {
      return this.first_company_id;
    }
  }

  public get verifyPortingAccess(): boolean {
    return (
      this.features.includes('porting_in') ||
      !this.features.includes('international_calling')
    );
  }

  public get showIntegrationsUpsell(): boolean {
    return this.plan_tier && this.plan_tier === 'Lite';
  }

  public get poolSizeControlProps(): PoolSizeControl {
    return {
      trial: this.trial,
      billable_customer: this.billable_customer,
      session_tracker_min_size: this.session_tracker_min_size,
      keyword_pool_size_max: this.keyword_pool_size_max,
      max_phone_numbers: this.max_phone_numbers,
      number_count: this.number_count,
    };
  }

  public get isTwilioRegistered(): boolean {
    return ['registered_in_twilio', 'internal_same_network'].includes(
      this.brand_status
    );
  }

  private get whiteLabelLogo(): string {
    if (this.white_label) {
      return this.white_label_attributes.white_label_header_logo_url;
    }
  }

  private get legacyLogo(): string {
    if (this.white_label) {
      return this.white_label_attributes.white_label_legacy_logo;
    }
  }
}
