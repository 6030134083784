import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentRefService {
  public nativeDocument(): any {
    return document;
  }
}
