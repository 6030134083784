import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CognitoCookieService {
  private readonly lastVisitedUrlKey = 'last_visited_url';
  private readonly userSignedOutKey = 'user_signed_out';
  private readonly blockedUrls = [
    '/account-center/choose-account',
    '/authenticate',
    '/authenticate/sign-out',
    '/settings/a/.*/new-user/.*',
    '/users/sign_in',
  ];

  constructor(private cookieService: CookieService) {}

  public signOut() {
    this.resetLastVisitedUrl();
    this.userSignedOut = Date.now().toString();
  }

  public get lastVisitedUrl(): string {
    return this.get(this.lastVisitedUrlKey);
  }

  public set lastVisitedUrl(url: string) {
    const blocked = this.blockedUrls.some((blockedUrl) => {
      const check = new RegExp(blockedUrl, 'i');
      return check.test(url);
    });

    if (blocked) {
      return;
    }

    this.set(this.lastVisitedUrlKey, url);
  }

  public resetLastVisitedUrl() {
    this.delete(this.lastVisitedUrlKey);
  }

  public hasUserSignedOut(): boolean {
    return this.check(this.userSignedOutKey);
  }

  public get userSignedOut(): string {
    return this.hasUserSignedOut() ? this.get(this.userSignedOutKey) : null;
  }

  public set userSignedOut(date: string) {
    this.set(this.userSignedOutKey, date);
  }

  public resetUserSignedOut() {
    this.delete(this.userSignedOutKey);
  }

  // Using ngx-cookie-service implementation
  public check(key: string): boolean {
    return this.cookieService.check(key);
  }

  public get(key: string): string {
    return this.cookieService.get(key);
  }

  public set(key: string, value: string) {
    this.cookieService.set(key, value, null, '/');
  }

  public delete(key: string) {
    this.cookieService.delete(key, '/');
  }
}
