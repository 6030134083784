import { Inject, Injectable, Optional } from '@angular/core';

declare global {
  interface Window {
    isMobile?: boolean;
    isSalesforceCanvas?: boolean;
    isRingCentralIframe?: boolean;
    isSemrushIframe?: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  constructor(@Optional() @Inject('window') private window?: any) {}

  public nativeWindow(): any {
    /*
      used in various parts of our angular apps to
      dermine what to do for mobile users

      taken from: http://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-handheld-device-in-jquery
    */
    this._nativeWindow.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        this._nativeNavigator.userAgent
      );

    return this._nativeWindow;
  }

  public get _nativeWindow() {
    return this.window || (window as any);
  }

  public get _nativeNavigator() {
    return navigator;
  }

  public get isSalesforceCanvas(): boolean {
    return (this.nativeWindow() as Window).isSalesforceCanvas;
  }

  public get isRingCentralIframe(): boolean {
    return (this.nativeWindow() as Window).isRingCentralIframe;
  }

  public get isSemrushIframe(): boolean {
    return (this.nativeWindow() as Window).isSemrushIframe;
  }

  public postTopMessage(message: any, target: string): void {
    this.nativeWindow().top?.postMessage(message, target);
  }
}
