import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorHelperService {
  constructor(
    private http: HttpClient,
    @Optional() @Inject('env') private env?: any // looky apps
  ) {}

  public helpfulNamedError(error) {
    // Some errors get grouped together and we need to differentiate them in Honeybadger better for grouping and scannability
    switch (error.name) {
      case 'HttpErrorResponse':
        const url = error.url || this.xhrURL(error) || '';
        error.name =
          error.name + '::' + error.status + '::' + this.endpoint(url); // HttpErrorResponse::500::calls.json
        break;
      case 'TypeError':
        if (error.message || error.message.message) {
          error.name =
            error.name +
            ': ' +
            (error.message ? error.message : error.message.message).substring(
              0,
              80
            );
        }
        break;
    }

    return error;
  }

  public get isMySpaFresh(): Observable<boolean> {
    if (this.env.staging || this.env.development) {
      return of(true);
    }
    const app = 'anubis';
    const url = `https://calltrk-build-artifacts-prod1.s3.amazonaws.com/looky/${app}/main/latest/sha`;
    return this.http
      .get(url, { responseType: 'text' })
      .pipe(map((lastestSha) => lastestSha === process.env.REVISION));
  }

  private endpoint(url: string): string {
    for (let part of url.split('/').reverse()) {
      part = part.replace(/[#|\?|&].*/, ''); // remove query string
      if (part.match(/^[a-z|A-Z]/)) {
        return part;
      } // starts with a letter
    }
  }

  private xhrURL(error): string {
    if (error && error.error && error.error.target) {
      return error.error.target.__zone_symbol__xhrURL; // "/a/243316838/current_plan.json"
    }
  }
}
