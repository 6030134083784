/**
 * @cabooseInclude
 * @hierarchy [controls-user-input][Form Controls]
 * @tags: []
 * @title [Checkbox Pill]
 */

import { SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const PILL_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxPillComponent),
  multi: true,
};

/**  A component that exposes checkbox behavior, but in the style of clickable pills.*/
@Component({
  selector: 'ui-checkbox-pill',
  providers: [PILL_CONTROL_ACCESSOR],
  templateUrl: './checkbox-pill.component.html',
})
export class CheckboxPillComponent implements ControlValueAccessor {
  /**
   * Text to show inside the pill
   */
  @Input() text: string;

  /**
   * To two-way bind, use `[(value)]`
   */
  @Input() set value(input: boolean) {
    this._checked = input;
  }

  /**
   * Emits a boolean value anytime a checkbox value is changed.
   */
  @Output() valueChange = new EventEmitter<boolean>();

  public _checked = false;

  /**
   * ControlValueAccessor functions
   */
  public registerOnTouched(fn): void {
    this._onTouched = fn;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public writeValue(value: boolean): void {
    // use the setter since it isn't an internally-initiated change
    this.value = value;
  }

  /**
   * Unfortunate, but we can't directly interact with the checkbox if we want to avoid focus
   */
  @HostListener('click', ['$event'])
  public clickEvent(event) {
    this.toggleChecked();
    event.preventDefault();
  }

  /**
   * Unfortunate, but we can't directly interact with the checkbox if we want to avoid focus
   */
  @HostListener('keydown', ['$event'])
  public keyEvent(event) {
    if (event.keyCode === SPACE) {
      this.toggleChecked();
      event.preventDefault();
    }
  }

  // for control value accessor
  private _onTouched = () => {
    return;
  };
  private _onChange = (value) => {
    return;
  };

  private toggleChecked() {
    this._checked = !this._checked;
    this.valueChange.emit(this._checked);
    this._onChange(this._checked);
    this._onTouched();
  }
}
