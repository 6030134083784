import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AmplifyFormComponent,
  SSOComponent,
  AmplifySignoutComponent,
} from '@callrail/looky/anubis/login/shared';

const routes: Routes = [
  {
    path: '',
    component: AmplifyFormComponent,
  },
  {
    path: 'sso',
    component: SSOComponent,
  },
  {
    path: 'sign_out',
    component: AmplifySignoutComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    // initialNavigation is "disabled" in order to allow APP_INITIALIZER to resolve first and then
    // the app.component.ts calls router.initialNavigation() which facilitates the same thing
    RouterModule.forRoot(routes, { initialNavigation: 'disabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
