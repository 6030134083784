import {
  APP_BASE_HREF,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiProxyInterceptor } from '@callrail/looky/core';

import { env } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AnubisInitService } from './services/anubis-init/anubis-init.service';
import { LayoutAlertFlashModule } from '@callrail/looky/common/layout/shared';

// Modules
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { AppComponent } from './app.component';

import { BuilderModule } from '@builder.io/angular';
import {
  AnubisLoginModule,
  ErrorCatcher,
} from '@callrail/looky/anubis/login/shared';

const devProviders = env.development
  ? [{ provide: HTTP_INTERCEPTORS, useClass: ApiProxyInterceptor, multi: true }]
  : [];

const init = (anubisInit: AnubisInitService) => () => anubisInit.init();

@NgModule({
  declarations: [AppComponent],
  imports: [
    AnubisLoginModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    BuilderModule.forRoot('18cf0bc1157f43cbaa8aed1276d9b9ad'),
    AmplifyAuthenticatorModule,
    LayoutAlertFlashModule,
  ],
  providers: [
    ...devProviders,
    { provide: APP_BASE_HREF, useValue: '/authenticate/' },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [AnubisInitService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: ErrorHandler, useClass: ErrorCatcher },
    { provide: 'env', useValue: env },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
