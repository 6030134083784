import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { HeaderTokenAuthTokenResponse } from '../../interfaces/header-token-auth-token-response';

@Injectable({
  providedIn: 'root',
})
export class SalesforceSignedRequestsService {
  constructor(private http: HttpClient) {}

  public token(
    path: string,
    params: { signed_request: string }
  ): Observable<HeaderTokenAuthTokenResponse> {
    if (!path) {
      return throwError(
        () => new Error('SalesforceSignedRequestsService: missing path')
      );
    }
    return this.http.post<HeaderTokenAuthTokenResponse>(path, params);
  }
}
