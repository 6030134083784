import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamsService } from '@callrail/looky/util';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Address } from '../../interfaces/address';
import { CompanyQueryParams } from '../../interfaces/company-query-params';
import { TimeZone } from '../../interfaces/time-zone';
import { AgencyModel } from '../../models/agency.model';
import { CompanyModel } from '../../models/company.model';
import { Country } from '../../models/country.model';

interface Params {
  [key: string]: boolean | string;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public companyAdded$: Subject<string | number> = new Subject();
  public companyDisabled$: Subject<string | number> = new Subject();

  constructor(
    private paramsService: ParamsService,
    private http: HttpClient
  ) {}

  public get(
    company_id: number | string,
    params?: Params
  ): Observable<CompanyModel> {
    let url = CompanyModel.getRoute(company_id) + '.json';
    if (params) {
      url = this.paramsService.appendToString(url, params);
    }
    return this.http
      .get<CompanyModel>(url)
      .pipe(map((companyData) => new CompanyModel(companyData)));
  }

  public update(
    company: Partial<CompanyModel>,
    params?: Params
  ): Observable<CompanyModel> {
    const url = CompanyModel.getRoute(company.id) + '.json';
    params = Object.assign({ company }, params);
    return this.http.put<CompanyModel>(url, params);
  }

  /**
   * An endpoint that returns an agencies companies. It only returns the id, name, and company status by default
   * @param agency_id agency id that you'd like the companies for
   * @param with_trackers_data Makes the endpoint return the number of active trackers for each company
   * @param with_real_ids The with_real_ids params is for superadmin use only and causes the endpoint to return real ids instead of masked ids
   */
  public simpleList(
    agency_id: string,
    with_trackers_data?: boolean,
    with_real_ids?: boolean
  ): Observable<CompanyModel[]> {
    let url = `${AgencyModel.getRoute(agency_id)}/companies.json?simple=true`;
    url = with_trackers_data ? url + '&with_trackers_data=true' : url;
    url = with_real_ids ? url + '&with_real_ids=true' : url;
    return this.http.get<CompanyModel[]>(url).pipe(
      map((companyDataArr) => {
        return companyDataArr
          ? companyDataArr.map((company) => new CompanyModel(company))
          : [];
      })
    );
  }

  public query(agency_id: string, opts?: CompanyQueryParams): Observable<any> {
    let url = `${AgencyModel.getRoute(agency_id)}/companies.json`;
    if (opts) {
      url = this.paramsService.appendToString(url, opts);
    }
    return this.http.get<any>(url).pipe(
      map((companyData) => {
        return {
          ...companyData,
          companies: companyData.companies.map(
            (company) => new CompanyModel(company)
          ),
        };
      })
    );
  }

  public delete(companyId: string, deleteUsers: boolean): Observable<any> {
    let url = CompanyModel.getRoute(companyId);
    if (deleteUsers) {
      url = this.paramsService.appendToString(url, {
        delete_users: deleteUsers,
      });
    }
    return this.http
      .delete<any>(url)
      .pipe(tap(() => this.companyDisabled$.next(companyId)));
  }

  public exclusiveUsersCount(
    agencyId: string,
    companyId: string
  ): Observable<any> {
    let url = `${CompanyModel.getRoute(companyId)}/num_exclusive_users`;
    url = this.paramsService.appendToString(url, { agency_id: agencyId });
    return this.http.get<CompanyModel>(url);
  }

  public countries(companyId: string): Promise<Country[]> {
    const url = `${CompanyModel.getRoute(companyId)}/countries`;
    return this.http.get<Country[]>(url).toPromise();
  }

  public create(params: any, agency_id: string): Observable<CompanyModel> {
    const url = `${AgencyModel.getRoute(agency_id)}/companies.json`;
    return this.http
      .post<CompanyModel>(url, params)
      .pipe(tap((company) => this.companyAdded$.next(company.id)));
  }

  public createAddress(
    agencyId: string,
    companyId: string,
    addressToSend: Partial<Address>
  ): Observable<Address> {
    const url = `${CompanyModel.getRoute(
      companyId
    )}/addresses?agency_id=${agencyId}`;
    return this.http.post<Address>(url, addressToSend);
  }

  public updateAddress(
    agencyId: string,
    companyId: string,
    addressId: string | number,
    addressToSend: Partial<Address>
  ): Observable<Address> {
    const url = `${CompanyModel.getRoute(
      companyId
    )}/addresses/${addressId}?agency_id=${agencyId}`;
    return this.http.put<Address>(url, addressToSend);
  }

  public getAddresses(
    agencyId: string,
    companyId: string
  ): Observable<Address[]> {
    const url = `${CompanyModel.getRoute(
      companyId
    )}/addresses?agency_id=${agencyId}`;
    return this.http.get<Address[]>(url);
  }

  public getTimeZones(agencyId: string): Observable<TimeZone[]> {
    const url = `${AgencyModel.getRoute(agencyId)}/companies/time_zones.json`;
    return this.http.get<TimeZone[]>(url);
  }

  public agents(companyId: string): Observable<[]> {
    const url = `${CompanyModel.getRoute(companyId)}/agents`;
    return this.http.get<[]>(url);
  }
}
