import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { WindowRefService } from '@callrail/looky/util';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { fsSession } from '../../constants/full-story-session';
import { AppContextService } from '../app-context/app-context.service';
import { CurrentContextService } from '../current-context/current-context.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHelperService {
  constructor(
    private context: CurrentContextService,
    private appContext: AppContextService,
    private http: HttpClient,
    private windowRefService: WindowRefService,
    @Optional() @Inject('env') private env?: any // looky apps
  ) {}

  public doNotify(error): boolean {
    // skip window.onerror CORS issue https://app.honeybadger.io/projects/56607/faults/38611350#notice-summary
    if (
      error.name === 'window.onerror' &&
      ((typeof error.message === 'string' &&
        error.message.match(/["']isTrusted["']=>["']true["']/)) ||
        (typeof error.message === 'object' &&
          error.message.message?.match(/Script error/)))
    ) {
      return false;
    } else if (
      error.status === 0 &&
      error.name &&
      error.name.match(/HttpErrorResponse/)
    ) {
      // Status 0 is not a valid response code. It is the code set before the request completes and in the case of
      // XMLHttpRequest errors, it appears as the response code. These errors could happen for a variety of reasons:
      // 1.) Cors issue like No Access-Control-Allow-Origin header present
      // 2.) net::ERR_CONNECTION_REFUSED (server not responding)
      // 3.) client could not receive the response within the timeout period (slow connection)
      // 3.) net::ERR_CERT_COMMON_NAME_INVALID (http requesting https protocol)
      // When this happens, unless it was affecting EVERYONE, there's not much we can do about it.
      // It's possible we could create such a bug that affected everyone and this would tell us about it, but the
      // velocity at which we receive this for all the other reasons would drown the possiblity of noticing it
      return false;
    } else if (
      error?.message?.includes('"status":0,') &&
      error?.message?.includes('"name":"HttpErrorResponse"')
    ) {
      // Sometimes the above error object is provided as stringified JSON in the message body.
      // This rule is meant to detect the relevant properties inside that string.
      return false;
    } else if (
      error.status === 401 ||
      error?.message?.includes('"status":401,')
    ) {
      // there are far too many reasons we would intercept a 401 (unauthorized) response from a request to make it
      // useful in honeybadger for debugging. The most common one is their Devise session has expired which could be
      // on any url. We would forever be chasing our tails. So dont report those.
      return false;
    } else if (
      error.status === 403 ||
      error?.message?.includes('"status":403,')
    ) {
      // there are far too many reasons we would intercept a 403 (forbidden) response from a request to make it
      // useful in honeybadger for debugging. The most common ones are an account which is no longer active, or
      // a high-traffic agency has hit a rate limit in API requests.
      return false;
    } else if (
      error.status === 404 ||
      error?.message?.includes('"status":404,')
    ) {
      // not found errors would seem useful in determining if we've created a user experience where they are not
      // able to access something they should be able to ... but given that Hackerone uncovered a brute force
      // vulnerabilty in our previous responses on some endpoints to return 200 but with a message saying something
      // couldnt be done ... we've intentionally started throwing 404's in places. So badgerin on these now, with
      // the sheer velocity that they occur, prevent us from really debugging a smaller issue that may be occuring
      return false;
    } else if (
      error.status === 414 ||
      error?.message?.includes('"status":414,')
    ) {
      // like many of the other HTTP error codes, 414 aka "URI is too long" is not useful for the frontend to
      // worry about. We do make incredibly long URIs sometimes by cconfiguring report filters.
      return false;
    } else if (
      error.status === 502 ||
      error?.message?.includes('"status":502,') ||
      error.status === 500 ||
      error?.message?.includes('"status":500,')
    ) {
      // 502s are errors that occur on the server side that
      // we receive on the front-end for various reasons.
      // Those reasons are better understood and tracked
      // in HB on the server side.
      // We don't get any useful errors with 502s on the front.
      return false;
    } else if (
      error.status === 408 ||
      error?.message?.includes('"status":408,')
    ) {
      // 408s are errors that occur on the server side that
      // we receive on the front-end for pg statement timeout reasons.
      // Those reasons are better understood and tracked
      // in HB and Datadog on the server side
      // We don't get any useful errors with 408s on the front.
      return false;
    } else if (
      error.status === 504 ||
      error?.message?.includes('"status":504,')
    ) {
      // 504 = timeout -- these are generally user specific and the work to resolve these can be very time consuming
      // and generally much lower priority, which leads the badgers to build up with no attention.
      return false;
    } else if (error?.error?.text?.match(/meshimer/i)) {
      // some users use the service https://www.meshimer.com/. this service does some sort of http intercepting and replaces our
      // http response with an HTML blurb that includes text like "ACCESS TO THIS PAGE IS RESTRICTED".
      // there's nothing we can do to solve this issue, other than tell the customers to white list our domain -- which is
      // something we can not solve with code!
      return false;
    } else if (
      error?.error?.message?.includes('ChunkLoadError') ||
      error?.message?.includes('ChunkLoadError')
    ) {
      return false;
    } else if (
      error?.message?.includes(
        'SecurityError: No permission to use requested device'
      )
    ) {
      // This error is generated if the app trries to access the microphone without the user's permission.
      // In the Lead Center agent tool, efforts are made to notify the user and disable certain actions in the UI
      // if mic permissions haven't yet been given. This isn't fool-proof, though. For example, a user can revoke mic
      // permissions while on an active call and then receive this error.
      // When this error is encountered, it is because of browser or system interactions that are out of our control
      // and impossible to code around. The best we can do it notify the user and not bother HB with it:
      return false;
    } else if (error?.message?.includes('NotAllowedError: Permission denied')) {
      // Similar to the previous rule, this error will also be thrown if mic permissions are revoked while the
      // microphone is being used:
      return false;
    } else if (
      error?.message?.includes('NotFoundError: Requested device not found')
    ) {
      // Similar to the previous rule, this message will be thrown if the browser tries to interact with a system device
      // (microphone, camera) that has become disconnected or unresponsive wince the page first loaded. The device
      // might be permenently offline or just momentarily; there are counless reasons as well. Low voltage on the CPU,
      // USB cable is unconnected/failing, to name a few. All we know is that the device was registered with the browser
      // on page load, but now that we're trying to use it, it's unavailable:
      return false;
    } else if (error?.message?.includes('EncodingError: Decoding failed')) {
      // Sometimes the browser is unable to decode a given audio file. It could be that the file wasn't encoded
      // correctly, or corrupted during transmission, or even a bug in the browser (Safari 15 shipped with this bug).
      // Either way, nothing for us to do about it.
      return false;
    }

    return true;
  }

  public metaData(error, additionalMetaData?) {
    const context = this.hbContext(error, additionalMetaData);
    const metaData = { context } as any;
    const fingerprint = this.fingerprint(error);
    if (fingerprint) {
      metaData.fingerprint = fingerprint;
    }
    return metaData;
  }

  public helpfulNamedError(error) {
    // Some errors get grouped together and we need to differentiate them in Honeybadger better for grouping and scannability
    switch (error.name) {
      case 'HttpErrorResponse':
        const url = error.url || this.xhrURL(error) || '';
        error.name =
          error.name + '::' + error.status + '::' + this.endpoint(url); // HttpErrorResponse::500::calls.json
        break;
      case 'TypeError':
        if (error.message || error.message.message) {
          error.name =
            error.name +
            ': ' +
            (error.message ? error.message : error.message.message).substring(
              0,
              80
            );
        }
        break;
    }

    return error;
  }

  public get isMySpaFresh(): Observable<boolean> {
    if (
      !this.appContext.isLookyApp ||
      this.env.staging ||
      this.env.development
    ) {
      return of(true);
    }
    let app = this.appContext.currentAppName; // cfb, lead-center, superadmin, analytics, account-center
    if (app === 'sa') {
      app = 'superadmin';
    }
    const url = `https://calltrk-build-artifacts-prod1.s3.amazonaws.com/looky/${app}/main/latest/sha`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((lastestSha) => lastestSha === process.env.REVISION),
      catchError(() => of(true)) // if we cant get the latest sha, assume its fresh
    );
  }

  private hbContext(error, additionalMetaData) {
    return Object.assign(
      {
        user: this.context.currentUser ? this.context.currentUser.id : null,
        role: this.context.currentUser ? this.context.currentUser.role : null,
        white_label: this.context.currentUser
          ? this.context.currentUser.white_label
          : null,
        agency: this.context.currentAgency
          ? this.context.currentAgency.id
          : null,
        company: this.context.currentCompany
          ? this.context.currentCompany.id
          : null,
        xhr_url: this.xhrURL(error),
        fs_session: fsSession(true),
        error: {
          error: error.error,
          errors: error.errors,
          message: error.message,
          name: error.message,
          ok: error.ok,
          status: error.status,
          statusText: error.statusText,
          url: error.url,
        },
        tags: this.tags,
        component: this.getModuleFromError(error),
      },
      additionalMetaData || {}
    );
  }

  private fingerprint(error): string {
    // poor connections plus large chunk files cause this error to constantly happen and every
    // one of them are grouped different based upon which chunk file + hashing it was for. So group them
    if (
      typeof error.message === 'string' &&
      error.message.match(/[Ll]oading chunk.*failed.*timeout/)
    ) {
      return 'timeout loading chunk';
    } else if (error.name.match(/HttpErrorResponse/)) {
      return error.name; // HttpErrorResponse::500::calls.json
    } else if (
      typeof error.message === 'string' &&
      error.message.match(/Invalid semrush destination/)
    ) {
      return error.message.replace(/\s/g, '-');
    }
  }

  private endpoint(url: string): string {
    for (let part of url.split('/').reverse()) {
      part = part.replace(/[#|\?|&].*/, ''); // remove query string
      if (part.match(/^[a-z|A-Z]/)) {
        return part;
      } // starts with a letter
    }
  }

  private xhrURL(error): string {
    if (error && error.error && error.error.target) {
      return error.error.target.__zone_symbol__xhrURL; // "/a/243316838/current_plan.json"
    }
  }

  private get tags(): string {
    const app = this.appContext.currentAppName;
    const list = [app];
    if (this.windowRefService.isSemrushIframe) {
      list.push('semrush');
    }
    return list.join(', ');
  }

  private getModuleFromError(error): string {
    const { stack } = error;
    if (!stack) {
      return '';
    }
    const lines = stack.split('\n');
    const firstStackLine = lines.find((line) => line.trim().startsWith('at'));
    if (!firstStackLine) {
      return '';
    }
    const moduleRegex = new RegExp(/\/packs\/([a-zA-Z\-]+)\/([0-9]+)[.\-]/);
    const packRegex = new RegExp(/\/packs\/(.*)\//);
    const moduleMatch = firstStackLine.match(moduleRegex);
    const packMatch = firstStackLine.match(packRegex);
    if (moduleMatch) {
      return `${moduleMatch[1]}-module-${moduleMatch[2]}`;
    } else if (packMatch) {
      return packMatch[1];
    } else {
      return '';
    }
  }
}
