<label
  [class.large]="size === 'large'"
  [class.align-top]="align === 'top'">
  <input
    type="checkbox"
    [checked]="isChecked"
    [attr.disabled]="isDisabled" />
  <span class="checkbox">
    <i class="icon-check"></i>
  </span>
  <span
    #labelContent
    [class.u-padding-left-10]="labelContent.innerHTML.length">
    <ng-content></ng-content>
  </span>
</label>
