import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FullstoryServiceMock {
  public fs = {
    init: () => ({}),
    identify: () => ({}),
  };
  public initialized() {
    return;
  }
}
