import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  LocationService,
  HoneyBadgerService,
  HoneyBadgerServiceMock,
} from '@callrail/looky/util';
import { filter, take } from 'rxjs/operators';

import { CurrentContextService } from '../current-context/current-context.service';
import { ErrorHelperService } from './error-helper.service';
import { DatadogRumService } from '../datadog-rum/datadog-rum.service';
import { DatadogRumServiceMock } from '../datadog-rum/datadog-rum.service.mock';

// https://docs.honeybadger.io/lib/javascript/guides/breadcrumbs.html
export type BreadcrumbCategory =
  | 'custom'
  | 'error'
  | 'query'
  | 'job'
  | 'request'
  | 'render'
  | 'log'
  | 'notice';

@Injectable({
  providedIn: 'root',
})
export class ErrorReporterService {
  private hb: any;

  constructor(
    private context: CurrentContextService,
    private helper: ErrorHelperService,
    private injector: Injector, // to prevent real hb service from eager loading in dev|tests
    private locationService: LocationService,
    @Optional() private router: Router,
    @Optional() @Inject('env') private env?: any, // from looky apps
    @Optional() @Inject('railsEnv') private railsEnv?: any // from callrail apps
  ) {
    this.hb = this.hbService.hb;
    this.hb.configure(this.hbSettings);
    this.hb.beforeNotify((error) => this.helper.doNotify(error));
    this.context.user.subscribe((user) => {
      if (user?.superadmin) {
        this.hb.afterNotify((error, notice) => {
          // eslint-disable-next-line no-console
          console.log(
            notice
              ? `Honeybadger notice: https://app.honeybadger.io/notice/${notice?.id}`
              : 'Failed to report to HB'
          );
        });
      }
    });
    this.resetErrorCountOnNav();
  }

  public report(error, additionalMetaData?) {
    error = this.helper.helpfulNamedError(error);
    if (this.helper.doNotify(error)) {
      this.helper.isMySpaFresh.pipe(take(1)).subscribe(
        (isSpaFresh) => {
          if (isSpaFresh) {
            this.logAndNotify(error, additionalMetaData);
          }
        },
        (err) => {
          err.name = `SpaFreshnessError::${err.name}`;
          this.logAndNotify(err, { ...additionalMetaData });
        }
      );
    }
  }

  public addBreadcrumb(
    message: string,
    metadata: {} = null,
    category: BreadcrumbCategory = 'custom'
  ): void {
    if (message) {
      const params = {} as any;
      if (metadata) {
        params.metadata = metadata;
      }
      if (category) {
        params.category = category;
      }
      this.hb.addBreadcrumb(message, params);
    }
  }

  private logAndNotify(error, additionalMetaData?) {
    const devOrTestMetadata = this.isDevOrTestEnv ? additionalMetaData : {};
    // eslint-disable-next-line no-console
    console.error('error', error, devOrTestMetadata); // in production, DO NOT put metadata in this
    this.hb.notify(error, this.helper.metaData(error, additionalMetaData));
    this.ddService.addError(
      error,
      this.helper.metaData(error, additionalMetaData)
    );
  }

  private get isDevOrTestEnv() {
    return (
      (this.env && !this.env.production) ||
      (this.railsEnv && this.railsEnv.development)
    );
  }

  // we are lazy loading the honeybadger service below with injector because we dont want to load the real hb
  // service at all during dev or test since that honeybadger-js import throws errors regardless if its setup or not
  private get hbService() {
    if (this.isDevOrTestEnv) {
      return this.injector.get(HoneyBadgerServiceMock);
    } else {
      return this.injector.get(HoneyBadgerService);
    }
  }

  private get ddService() {
    if (this.isDevOrTestEnv) {
      return this.injector.get(DatadogRumServiceMock);
    } else {
      return this.injector.get(DatadogRumService);
    }
  }

  private get backendEnv() {
    if (this.context && this.context.env) {
      return this.context.env.environment;
    } else {
      // could get here in looky app if an err was thrown prior to app-init logic being resolved
      // since we dont know what the backend env is here, make an educated guess based on the url
      const host = this.locationService.host(); // app.staging-callrail.com
      return host.match(/-callrail/) ? 'staging' : 'production';
    }
  }

  private get hbSettings() {
    if (this.isDevOrTestEnv) {
      return {};
    } else {
      // always use the rails backend env as the "environment" value
      return {
        apiKey: 'd5b41121',
        breadcrumbsEnabled: {
          console: false,
        },
        maxErrors: 20,
        environment: this.backendEnv,
        revision: process.env.REVISION,
        enableUnhandledRejection: false,
      };
    }
  }

  private resetErrorCountOnNav(): void {
    if (this.router) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => this.hb?.resetMaxErrors());
    }
  }
}
