/** @cabooseInclude */

/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable no-underscore-dangle */
import { FocusableOption, Highlightable } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { OptionSelectionChange } from './option-selection-change';

export interface SelectOptionItem {
  name: string;
}

/**
 * Describes a parent component that manages a list of options.
 * Contains properties that the options can inherit.
 * @docs-private
 */
export interface SelectOptionParentComponent {
  multiple?: boolean;
}

/**
 * Injection token used to provide the parent component to options.
 */
export const SELECT_OPTION_PARENT_COMPONENT =
  new InjectionToken<SelectOptionParentComponent>(
    'SELECT_OPTION_PARENT_COMPONENT'
  );

/**
 * Option IDs need to be unique across components, so this counter exists outside of
 * the component definition.
 */
let _uniqueIdCounter = 0;

@Component({
  selector: 'ui-select-option',
  templateUrl: './select-option.component.html',
  host: {
    class: 'cr-option',
    '[class.cr-option--disabled]': 'disabled',
    '[class.cr-option--selected]': '_selected',
    '[class.cr-option--multiple]': 'multiple',
    '[class.cr-option--active]': 'active',
    '[attr.aria-selected]': 'selected.toString()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[attr.tabindex]': '_getTabIndex()',
    '(click)': 'selectViaInteraction()',
    '(keydown)': 'handleKeydown($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionComponent implements FocusableOption, Highlightable {
  @Input() item: SelectOptionItem | any;
  @Input() set disabled(val) {
    this._disabled = coerceBooleanProperty(val);
  }
  get disabled() {
    return this._disabled;
  }
  _disabled = false;

  @Input() set selected(val: boolean) {
    this._selected = coerceBooleanProperty(val);
  }
  get selected(): boolean {
    return this._selected;
  }
  _selected = false;

  _id = `cr-option-${_uniqueIdCounter++}`;
  /** The unique ID of the option. Not the id of the item. */
  get id(): string {
    return this._id;
  }

  _active = false;

  /** Whether the wrapping component is in multiple selection mode. */
  get multiple() {
    return this._parent && this._parent.multiple;
  }

  @Output() onSelectChange: EventEmitter<OptionSelectionChange> =
    new EventEmitter();

  // output to parent mouse enter event
  @Output() hasMouseEnter: EventEmitter<any> = new EventEmitter();

  constructor(
    public host: ElementRef,
    public _ref: ChangeDetectorRef,
    @Optional()
    @Inject(SELECT_OPTION_PARENT_COMPONENT)
    public _parent: SelectOptionParentComponent
  ) {}

  focus(): void {
    this.host.nativeElement.focus();
  }

  get active(): boolean {
    return this._active;
  }

  /** Ensures the option is selected when activated from the keyboard. */
  handleKeydown(event: KeyboardEvent): void {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      this.selectViaInteraction();

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();
    }
  }

  /** Selects the option. */
  select(): void {
    this._selected = true;
    this._emitSelectionChange();
  }

  /** Deselects the option. */
  deselect(): void {
    this._selected = false;
    this._emitSelectionChange();
  }

  selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = this.multiple ? !this._selected : true;
      this._emitSelectionChange(true);
    }
  }

  /**
   * This method sets display styles on the option to make it appear
   * active. This is used by the ActiveDescendantKeyManager so key
   * events will display the proper options as active on arrow key events.
   */
  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
      this._ref.markForCheck();
    }
  }

  /**
   * This method removes display styles on the option that made it appear
   * active. This is used by the ActiveDescendantKeyManager so key
   * events will display the proper options as active on arrow key events.
   */
  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
      this._ref.markForCheck();
    }
  }

  _emitSelectionChange(isUserInput = false): void {
    if (!this.disabled) {
      this.onSelectChange.emit(new OptionSelectionChange(this, isUserInput));
      this._ref.markForCheck();
    }
  }

  _getTabIndex(): '-1' | '2' {
    return this.disabled ? '-1' : '2';
  }
}
