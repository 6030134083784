/* eslint-disable no-underscore-dangle */

/**
 * @cabooseInclude
 * @hierarchy [controls-user-input][Form Controls]
 * @title [Checkbox]
 */

import { SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CHECKBOX_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

/** Displays a checkbox and label. */
@Component({
  selector: 'ui-checkbox',
  providers: [CHECKBOX_CONTROL_ACCESSOR],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements ControlValueAccessor {
  /** Indicates which size of checkbox to display */
  @Input() public size: 'default' | 'large' = 'default';

  /** Indicates whether to align the checkbox with the center of the label content, or the top (useful for multi-line labels) */
  @Input() public align: 'center' | 'top' = 'center';

  /** Indicates whether the checkbox is disabled */
  @Input() public disabled = false;

  /** Allows setting the checked attribute via [checked] */
  @Input() public set checked(input: boolean) {
    this.writeValue(input);
  }

  /** Allows for two-way data binding */
  @Input() public set value(input: boolean) {
    this._checked = input;
  }

  /** Emits the value on change */
  @Output() public valueChange = new EventEmitter<boolean>();

  public _checked = false;

  /**
   * ControlValueAccessor functions
   */
  public registerOnTouched(fn): void {
    this._onTouched = fn;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostListener('change', ['$event'])
  public changeEvent(event): void {
    this.toggleChecked();
    event.preventDefault();
  }

  @HostListener('keydown', ['$event'])
  public keyEvent(event): void {
    if (event.keyCode === SPACE) {
      this.toggleChecked();
      event.preventDefault();
    }
  }

  public get isChecked(): boolean {
    return this._checked ? true : null;
  }

  public get isDisabled(): boolean {
    return this.disabled ? true : null;
  }

  // For control value accessor
  private _onTouched = () => {
    return;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (_value) => {
    return;
  };

  private toggleChecked(): void {
    this.writeValue(!this._checked);
    this.valueChange.emit(this._checked);
    this._onChange(this._checked);
    this._onTouched();
  }
}
