import { Inject, Injectable, Injector, NgZone, Optional } from '@angular/core';
import {
  AssetsService,
  CssVariablesService,
  WhiteLabelAttributes,
} from '@callrail/looky/ui';
import {
  DocumentRefService,
  LocationService,
  WindowRefService,
  localStorageEnabled,
} from '@callrail/looky/util';

import { AgencyModel } from '../../models/agency.model';
import { UserModel } from '../../models/user.model';
import { CurrentContextService } from '../current-context/current-context.service';
import { FullstoryService } from '../fullstory/fullstory.service';
import { FullstoryServiceMock } from '../fullstory/fullstory.service.mock';
import { BackstageService } from '../backstage/backstage.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { isParamTokenAuth } from '../../constants/param-auth-tokens';
import { FullstoryTestingCookieService } from '../fullstory-testing-cookie/fullstory-testing-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitLayoutService {
  public fullStorySegmentPercent = 100; // number between 0 - 100
  public now = Date.now();
  private agency: AgencyModel;
  private user: UserModel;
  private appsThatAllowCustomBranding = [
    'cfb',
    'analytics',
    'settings',
    'anubis',
    'data',
    'messenger',
  ];

  private readonly destroy$ = new Subject<void>();

  // DON'T ADD ANYTHING THAT NEEDS AUTHENTICATION TO THIS OR IT WILL BREAK
  // THE TOKEN-BASED RECORDINGS PAGE. (ie FlippeService)
  constructor(
    private context: CurrentContextService,
    private assetsService: AssetsService,
    private documentRefService: DocumentRefService,
    private windowRefService: WindowRefService,
    private cssVariablesService: CssVariablesService,
    private injector: Injector,
    private backstageService: BackstageService,
    private ngZone: NgZone,
    private locationService: LocationService,
    private fsTestingCookieService: FullstoryTestingCookieService,
    @Optional() @Inject('env') private env?: any // from looky apps
  ) {}

  public setup(): void {
    this.agency = this.context.currentAgency;
    this.user = this.context.currentUser;
    this.setWindowItems();
    this.setBackstageEventsOrNot();
    this.setLocalUserRidOrNot();
  }

  public addStyles(
    app?: string,
    isWhiteLabel?: boolean,
    whiteLabelAttrs?: any
  ): void {
    if (!this.canModifyDom) {
      return;
    }
    // apply CSS variables to the page:
    if (this.isWlEnv || isWhiteLabel) {
      this.cssVariablesService.buildWLTheme(whiteLabelAttrs || this.wlAttrs);
    } else {
      this.cssVariablesService.buildCallrailTheme();
    }
    // attach a supplemental CSS stylesheet to the page -- but only on certain apps.
    // this stylesheet adds a layer of user-selected brand colors to things like the top
    //  nav and primary buttons.
    if (this.canAddWlCss && this.appsThatAllowCustomBranding.includes(app)) {
      this.addWlCss();
    }
  }

  public addFavicon(): void {
    if (!this.canModifyDom) {
      return;
    }
    if (this.isWlEnv) {
      this.addLink({
        rel: 'icon',
        type: 'image/vnd.microsoft.icon',
        href: this.asset('white_label_favicon.ico'),
      });
      this.addLink({
        rel: 'apple-touch-icon-precomposed',
        href: this.asset('whitelabel-iphone-bookmark-icon.png'),
      });
    } else {
      this.addLink({
        rel: 'icon',
        type: 'image/vnd.microsoft.icon',
        href: this.asset('favicon.ico'),
      });
      this.addLink({
        rel: 'apple-touch-icon-precomposed',
        href: this.asset('iphone-bookmark-icon.png'),
      });
    }
  }

  public addScripts(): void {
    if (!this.canModifyDom) {
      return;
    }
    if (this.isProdEnv || this.isTestEnv) {
      if (this.context.first_sign_in) {
        this.addScript('first-sign-in-header.js', 'head');
      } // keep first
      if (this.canAddAnalyticsScripts) {
        this.addAnalyticsScripts();
      }
      if (this.canAddRemarketingScript) {
        this.addScript('remarketing.js');
      }
      if (this.context.first_sign_in) {
        this.addScript('first-sign-in-footer.js');
      } // keep next to last
    }
    if (this.canAddFullStory) {
      this.initFullStory();
    }
    this.addScript('static.js'); // keep last
  }

  private get canAddAnalyticsScripts(): boolean {
    return !this.isCallRailUser && !this.userIsHipaa;
  }

  private get canAddFullStory(): boolean {
    if (this.isTestingFullStory) {
      return true;
    } else {
      return (
        (this.isProdEnv || this.isTestEnv) &&
        this.isInFullStorySegment &&
        !this.isCallRailUser &&
        !isParamTokenAuth(this.locationService.href)
      );
    }
  }

  private get isTestingFullStory(): boolean {
    return this.fsTestingCookieService.hasFsTestingCookie();
  }

  private get isInFullStorySegment(): boolean {
    const userIdValue = parseInt(this.user?.id, 10) + this.today();
    const userIsInSegment =
      userIdValue % 10 <= this.fullStorySegmentPercent / 10;
    return !!userIsInSegment;
  }

  private get canAddRemarketingScript(): boolean {
    return this.agency && !this.agency.white_label && !this.userIsHipaa;
  }

  private today(): number {
    return Math.floor(this.now / 86400000);
  }

  private addAnalyticsScripts(): void {
    if (this.canTrackAccount) {
      this.addScript('cookies.js');
    }
    this.addScript('google-analytics.js');
    this.addScript('churnzero.js');
  }

  private get document() {
    return this.documentRefService.nativeDocument();
  }

  private get window() {
    return this.windowRefService.nativeWindow();
  }

  private get isCallRailUser(): boolean {
    return this.user && !!(this.user.email || '').match(/@callrail\.com/);
  }

  private get userIsHipaa(): boolean {
    return this.user && this.user.hipaa;
  }

  private addLink(attrs = {}): void {
    this.addElement('link', 'head', attrs);
  }

  private addScript(name: string, location: 'head' | 'body' = 'body'): void {
    const attrs = {
      src: this.asset(`scripts/${name}`),
      type: 'text/javascript',
    };
    this.addElement('script', location, attrs);
  }

  private asset(name: string): string {
    return this.assetsService.resolve(name);
  }

  private addElement(
    type: 'link' | 'script',
    location: 'head' | 'body',
    attrs: any = {}
  ): void {
    const document = this.document;
    const el = document.createElement(type);
    for (const key of Object.keys(attrs)) {
      el[key] = attrs[key];
    }
    document[location].appendChild(el);
  }

  private get canAddWlCss(): boolean {
    return this.isWlEnv && !!this.wlAttrs.white_label_color;
  }

  private get wlAttrs(): WhiteLabelAttributes {
    return this.context[this.context.currentAgency ? 'currentAgency' : 'env']
      .white_label_attributes;
  }

  private stylesheetLinkOpts(name: string) {
    return {
      type: 'text/css',
      rel: 'stylesheet',
      media: 'all',
      href: this.asset(`styles/${name}`),
    };
  }

  private addWlCss(): void {
    this.addLink(this.stylesheetLinkOpts('wl-misc.css')); // grab bag
  }

  private get isWlEnv(): boolean {
    return !!(this.context.env && this.context.env.white_label);
  }

  private get canModifyDom(): boolean {
    const document = this.document;
    return !!(
      document &&
      document.head &&
      document.body &&
      document.documentElement
    );
  }

  private setWindowItems(): void {
    const win = this.window;
    win.WhiteLabel = this.isWlEnv;
    win.CompanyPusherChannels = this.user
      ? this.user.company_pusher_channels
      : undefined;
    win.CallTrkUi = {
      revision: process.env.REVISION,
      context: {
        user: this.user,
        agency: this.agency,
        company: this.context.currentCompany,
        env: this.context.env,
        sign_in_redirect: this.context.sign_in_redirect,
        eagerFlippers: this.context.eagerFlippers,
      },
    };
  }

  private setBackstageEventsOrNot(): void {
    this.backstageService.canShow$.pipe(take(1)).subscribe((canShow) => {
      if (canShow) {
        this.window.CallTrkUi.backstage = this.toggleBackstage.bind(this);
        this.window.addEventListener('keydown', (event) => {
          if (event.metaKey && event.shiftKey && event.code === 'Space') {
            // cmd + shift + space
            this.toggleBackstage();
          }
        });
      }
    });
  }

  private toggleBackstage(): void {
    this.ngZone.run(() => {
      // needed for use in js console (this.window.CallTrkUi.backstage())
      this.backstageService.isModalOpen = !this.backstageService.isModalOpen;
    });
  }

  private get canTrackAccount(): boolean {
    return (
      !this.agency ||
      !this.agency.white_label ||
      this.agency.current_users_role === 'admin'
    );
  }

  private get isTestEnv() {
    return this.env && this.env.test;
  }

  private get isProdEnv() {
    return this.env && this.env.production;
  }

  private get fsService() {
    if (this.isTestEnv) {
      return this.injector.get(FullstoryServiceMock);
    } else {
      return this.injector.get(FullstoryService);
    }
  }

  private initFullStory(): void {
    const orgId = 'callrail.com';
    const devMode = this.context.env.environment !== 'production';
    const debug = false;

    this.fsService.fs.init({ orgId, devMode, debug });

    const user = this.context.currentUser;
    const agency = this.context.currentAgency;

    if (user && agency) {
      this.fsIdentify(user, agency);
    }
  }

  private setLocalUserRidOrNot(): void {
    if (
      this.context.env?.environment !== 'production' &&
      localStorageEnabled()
    ) {
      localStorage.setItem('userRid', this.user?.resource_id);
    }
  }

  private fsIdentify(user: UserModel, agency: AgencyModel): void {
    const { rate_plans } = agency;
    const productPlanNames = (rate_plans || []).map((plan) => {
      return plan.name;
    });

    // To integrate with a customer support tool, make sure to set the email field using FS.identify.
    // The customer id should be whatever you use to identify a unique user in your system.
    // http://help.fullstory.com/develop-js/setuservars
    this.fsService.fs.identify(user.id.toString(), {
      displayName: user.name || '',
      email: user.email || '',
      userType_str: agency.current_users_role || '',
      accountName_str: agency.name || '',
      accountMaskedID_str: agency.id.toString(),
      planName_str: agency.current_plan || '',
      productPlans_strs: productPlanNames || '',
      accountCreatedAt_date: agency.created_at || '',
      userCreatedAt_date: user.created_at || '',
      isWhiteLabel: agency.white_label ? 'true' : '',
    });
  }
}
