import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { CognitoCookieService } from '@callrail/looky/core';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { Redirect } from './redirect';
import { SimpleAgency } from '../../interfaces/simple-agency';
import { MFAMigrationStateService } from '../mfa-migration/mfa-migration.service';

interface AgenciesResponse {
  agencies: SimpleAgency[];
}

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(
    private readonly http: HttpClient,
    private readonly mfaMigrationService: MFAMigrationStateService,
    private readonly cognitoCookieService: CognitoCookieService
  ) {}

  public agencies(): Observable<AgenciesResponse> {
    return this.http.get<AgenciesResponse>('/agencies', {
      params: { simple: true },
    });
  }

  public redirect(queryParams: ParamMap): Observable<{ redirect_to: string }> {
    return combineLatest([
      this.agencies(),
      this.mfaMigrationService.forceMfaMigration(),
    ]).pipe(
      switchMap(([{ agencies }, forceMfaMigration]) => {
        // if no agencies, redirect to root since they likely have a closed
        // account they're trying to get to.
        if (agencies.length === 0) {
          return of({ redirect_to: '/' });
        }
        const redirect = new Redirect(
          forceMfaMigration,
          queryParams,
          agencies,
          this.cognitoCookieService.lastVisitedUrl
        );
        if (redirect.zendesk()) {
          return this.zendeskTokenUrl(queryParams);
        } else {
          return of({ redirect_to: redirect.returnTo });
        }
      })
    );
  }

  private zendeskTokenUrl(
    params: ParamMap
  ): Observable<{ redirect_to: string }> {
    return this.http.get<{ redirect_to: string }>('/zendesk/sso', {
      params: {
        brand_id: params.get('brand_id'),
        locale_id: params.get('locale_id'),
        timestamp: params.get('timestamp'),
        return_to: params.get('return_to'),
      },
    });
  }
}
