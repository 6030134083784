declare let FS: any;

/**
 * https://help.fullstory.com/hc/en-us/articles/360020828073
 */
export function fsSession(now: boolean): string {
  if (typeof FS !== 'undefined' && FS.getCurrentSessionURL) {
    return FS.getCurrentSessionURL(now);
  }
}
