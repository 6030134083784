import { PhoneNumber } from '../interfaces/phone-number';
import { UserPreferences } from '../interfaces/user-preferences';
import { ViewedHelpMessages } from '../interfaces/viewed-help-messages';
import { UserRole } from '../type-aliases/user-roles';

import { CompanyModel } from './company.model';

export class UserModel {
  private _hipaa: boolean;
  private _supportLinks: boolean | undefined;

  public id: string;
  public agency_user_mid?: string;
  public agency_access?: boolean;
  public client_company_masked_ids?: (string | number)[];
  public companies?: CompanyModel[];
  public companies_count: number;
  public created_at?: string;
  public email?: string;
  public first_name?: string;
  public last_name?: string;
  public chat_name?: string;
  public mcc?: boolean;
  public paid_user?: boolean;
  public name?: string;
  public outbound_recording_enabled?: boolean;
  public current_password?: string;
  public password?: string;
  public password_confirmation?: string;
  public phone_numbers?: PhoneNumber[];
  public role_label?: string;
  public role?: UserRole;
  public roles?: UserRole[];
  public lc2_role?: 'agent';
  public timeout_in?: any;
  public user_type?: string;
  public white_label?: boolean;
  public superadmin?: boolean;
  public superadmin_roles?: string[];
  public tfa_enabled?: boolean;
  public sms_enabled?: boolean;
  public anubis_enabled?: boolean;
  public update_cognito_password?: boolean;
  public preferences?: UserPreferences;
  public screening?: boolean;
  public softphone_enabled?: boolean;
  public destination_numbers?: boolean;
  public viewed_help_messages?: ViewedHelpMessages;
  public executive_admin: boolean;
  public can_can_abilities: string[];
  public last_active_message?: string;
  public company_names?: string;
  public confirmation_needed?: boolean;
  public confirmation_sent_at?: string;
  public confirmed_at?: string;
  public resend_invitation_agency_user_path: string;
  public only_invoice_recipient?: boolean;
  public plain_text_emails: boolean;
  public receives_invoice: boolean;
  public notification_only: boolean;
  public primary_agency_id?: string;
  public show_active_session_tracker_reports?: boolean;
  public active_agencies_count?: number;
  public pending_agencies?: string[];
  public new_email?: string;
  public resource_id?: string;
  public company_pusher_channels?: string[];
  public agent_permissions?: boolean;
  public sub?: string;
  public provider?: 'google_oauth2' | 'cognito';

  // Lead Center properties, may live in phony and not be provided by default:
  public focused_inbox? = false;
  public allowed_to_monitor?: boolean;
  public team_view? = false;
  public voicemail_enabled? = false;

  constructor(fromData?: any) {
    Object.assign(this, fromData);
    this._supportLinks = fromData?.support_links;
  }

  public get route(): string {
    return UserModel.getRoute(this.id);
  }

  // we only send company_pusher_channels if the user has desktop
  // notification subscriptions for the current agency
  public get hasDesktopSubscriptions(): boolean {
    return this.company_pusher_channels?.length >= 1;
  }

  public static getRoute(id: number | string): string {
    return `/users/${id}`;
  }

  public optedIn(preference): boolean {
    return this.preferences[preference];
  }

  public get hipaa(): boolean {
    return this._hipaa;
  }

  public hasRole(roleToCompare: UserRole): boolean {
    const roles: UserRole[] = ['agent_only', 'reporting', 'manager', 'admin'];
    const userRoleLevel = roles.indexOf(this.role);
    const requiredRoleLevel = roles.indexOf(roleToCompare);
    return userRoleLevel >= requiredRoleLevel;
  }

  public canManage(ability): boolean {
    return this.can_can_abilities.includes(ability);
  }

  /**
   * Accessing this property directly is deprecated.
   * Use a reactive-based approach with `context.callRailAware$` instead.
   * @deprecated
   */
  public get support_links(): boolean {
    return this._supportLinks;
  }

  /**
   * Only used as convenience method for testing
   */
  public set support_links(val: boolean) {
    this._supportLinks = val;
  }
}
