import { Inject, Injectable, InjectionToken } from '@angular/core';
import { datadogRum, RumGlobal } from '@datadog/browser-rum';
import { filter, take } from 'rxjs/operators';
import { Context } from '../../models/context.model';
import { FullstoryService } from '../../services/fullstory/fullstory.service';

export function datadogFactory() {
  return datadogRum;
}

const DATADOG_RUM = new InjectionToken('datadogRum', {
  factory: datadogFactory,
});

@Injectable({
  providedIn: 'root',
})
export class DatadogRumService {
  constructor(
    @Inject(DATADOG_RUM) private datadog: RumGlobal,
    private fullStory: FullstoryService
  ) {}

  public initialize(context: Context, app: string): void {
    const {
      current_agency: currentAgency,
      current_user: currentUser,
      env,
    } = context;
    if (!currentAgency || !currentUser) {
      return;
    }
    const { rate_plans } = currentAgency;
    const planNames = (rate_plans || []).reduce((accum, rp) => {
      accum[rp.platform] = rp.name;
      return accum;
    }, {});

    // collect ALL datadog user sessions, only if user is in Lead Center. otherwise only collect 20% of user sessions:
    // const collectCallQuality = (app === 'lead-center' ? 100 : 20);
    const collectCallQuality = 20;

    if (['staging', 'production'].includes(env.environment)) {
      this.datadog.init({
        applicationId: 'e4ab784a-0bbd-4836-8a2a-c86ef1f76e2c',
        clientToken: 'pub137045a3c70849183cbe9b6adf1932a3',
        site: 'datadoghq.com',
        service: `callrail-angular-${app}`,
        env: env.environment,
        version: process.env.REVISION,
        sampleRate: collectCallQuality,
        trackInteractions: currentAgency ? !currentAgency.hipaa : true,
        allowedTracingOrigins: [
          'https://app.staging-callrail.com',
          'https://app.callrail.com',
          /https:\/\/.*\.qa-callrail\.com/,
        ],
      });
      this.datadog.addRumGlobalContext('agency', {
        id: currentAgency?.id,
        ...planNames,
      });
      this.datadog.addRumGlobalContext('usr', {
        id: currentUser?.resource_id,
        mid: currentUser?.id,
        name: currentUser?.name,
        email: currentUser?.email,
      });
      this.setupFullStory();
    }
  }

  public addError(error, metadata?: any): void {
    this.datadog.addError(error, metadata);
  }

  public addAction(action: { name: string; context?: any }): void {
    this.datadog.addAction(action.name, action.context);
  }

  private setupFullStory(): void {
    this.fullStory.fsInitialized$
      .pipe(
        filter((initialized) => initialized),
        take(1)
      )
      .subscribe(() => {
        this.datadog.addRumGlobalContext('full_story', {
          session_url: this.fullStory.fs.getCurrentSessionURL(),
        });
      });
  }
}
