import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StructuredAlertModule } from '@callrail/looky/ui';

import { LayoutAlertFlashComponent } from './layout-alert-flash.component';

@NgModule({
  declarations: [LayoutAlertFlashComponent],
  imports: [CommonModule, StructuredAlertModule],
  exports: [LayoutAlertFlashComponent],
})
export class LayoutAlertFlashModule {}
