import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  public getItem(key: string): any {
    const data = sessionStorage.getItem(key);
    if (data === '') {
      return null;
    }

    const parsedData = JSON.parse(data);
    if (parsedData === null) {
      return null;
    }
    if (!!parsedData[0]) {
      return parsedData[0];
    } else {
      return parsedData;
    }
  }

  public setItem(key: string, data: any): void {
    let item = data;
    if (typeof data !== 'object' || data.constructor !== Object) {
      item = { 0: data };
    }

    sessionStorage.setItem(key, JSON.stringify(item));
  }

  public clear(key: string): void {
    if (key) {
      sessionStorage.setItem(key, '');
    }
  }

  /**
   * This will clear ALL of the data in the session storage.
   * If it's feasible, please ONLY clear individual keys that you control instead of clearing everything
   */
  public clearAll(): void {
    sessionStorage.clear();
  }
}
