import { Injectable } from '@angular/core';
import { WindowRefService } from '@callrail/looky/util';
import { ReplaySubject } from 'rxjs';

import * as FullStory from '@fullstory/browser';

@Injectable({
  providedIn: 'root',
})
export class FullstoryService {
  private fsInitializedObs = new ReplaySubject<boolean>(1);
  public fsInitialized$ = this.fsInitializedObs.asObservable();

  constructor(windowRef: WindowRefService) {
    const nativeWindow = windowRef.nativeWindow();
    const oldFn = nativeWindow._fs_ready;
    nativeWindow._fs_ready = () => {
      if (oldFn) {
        oldFn();
      }
      this.fsInitializedObs.next(true);
    };
  }

  public get fs(): any {
    return FullStory;
  }

  public addCustomEvent(eventName: string, eventParams: object): void {
    if (FullStory.isInitialized()) {
      FullStory.event(eventName, eventParams);
    }
  }
}
