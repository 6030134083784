import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamsService } from '@callrail/looky/util';
import { LongRunningTask } from '../../models/long-running-task.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { NewUserParams } from '../../interfaces/new-user-params';
import { PhoneNumber } from '../../interfaces/phone-number';
import { UpdatePhoneNumbersParams } from '../../interfaces/update-phone-numbers-params';
import { UpdateUserParams } from '../../interfaces/update-user-params';
import { UserGetParams } from '../../interfaces/user-get-params';
import { UserPreferences } from '../../interfaces/user-preferences';
import { AgencyModel } from '../../models/agency.model';
import { UserModel } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public userCountChanged$ = new Subject<void>();
  public preferences = new Subject<UserPreferences>();

  constructor(
    private http: HttpClient,
    private paramsService: ParamsService
  ) {}

  public deleteAgencyUser(
    userId: string,
    agencyId: string,
    params?: { [key: string]: string }
  ): Observable<LongRunningTask> {
    let url = `${AgencyModel.getRoute(agencyId)}${UserModel.getRoute(
      userId
    )}.json`;
    if (params) {
      url = this.paramsService.appendToString(url, params);
    }
    return this.http.delete(url);
  }

  public getManagableUsers(agencyId: string, userId: string): Observable<any> {
    const url = `${AgencyModel.getRoute(
      agencyId
    )}/users/${userId}/managable_users`;
    return this.http.get(url);
  }

  public current(params: UserGetParams = {}): Observable<UserModel> {
    return this.http
      .get<UserModel>('/current_user', { params })
      .pipe(map((userData) => new UserModel(userData)));
  }

  public simpleList(
    agency_id: string,
    company_id?: string
  ): Observable<UserModel[]> {
    const params = {
      ...(company_id && { company_id }),
    };
    return this.http.get<UserModel[]>(`/a/${agency_id}/simple-users.json`, {
      params,
    });
  }

  public query(
    agencyId: string,
    params = {},
    company_id?: string
  ): Observable<any> {
    if (company_id) {
      params = {
        ...params,
        company_id,
      };
    }
    return this.http.get(`/a/${agencyId}/users.json`, { params });
  }

  public get(
    agencyId: string,
    userId: string,
    params: UserGetParams = {}
  ): Observable<UserModel> {
    const url = `${AgencyModel.getRoute(agencyId)}${UserModel.getRoute(
      userId
    )}.json`;
    return this.http.get<UserModel>(url, { params });
  }

  public update(
    user: UpdateUserParams,
    agencyId: string,
    fullParams = false
  ): Observable<UserModel> {
    const url = `${AgencyModel.getRoute(agencyId)}${UserModel.getRoute(
      user.id
    )}.json`;
    const agent = user.agent;
    const promote = user.promote;
    delete user.agent;
    delete user.promote;

    let params: any = fullParams ? { user, agent } : user;
    if (promote) {
      params = { ...params, promote };
    }

    return this.http.put<UserModel>(url, params);
  }

  public timeoutIn(id: number | string): Observable<number> {
    const url = `/users/${id}/timeout_in.json`;
    return this.http.get<number>(url);
  }

  public refreshSession(): Observable<any> {
    return this.http.get<any>('/health-check');
  }

  public updateRegistrationDetails(
    params: { user: UserModel; agent?: any },
    agencyId: string
  ): Observable<{ message: string }> {
    const url = `${AgencyModel.getRoute(agencyId)}/users.json`;
    return this.http.put<{ message: string }>(url, params);
  }

  public changeEmail(
    agencyId: string,
    new_email: string,
    current_password: string
  ): Observable<any> {
    const url = `/a/${agencyId}/users/change_email`;
    return this.http.patch(url, { new_email, current_password });
  }

  public updatePhoneNumbers(
    params: UpdatePhoneNumbersParams
  ): Observable<UserModel> {
    const url = `/a/${params.agencyId}/users/${params.userId}/user_phone_numbers`;
    const user_phone_numbers = params.phoneNumbers.map(
      (phoneNumber: PhoneNumber) => phoneNumber.phone_number
    );
    return this.http.put<UserModel>(url, { user_phone_numbers });
  }

  public disableDesktopNotifications(
    agencyId: string,
    userId: string
  ): Observable<any> {
    const url = `${AgencyModel.getRoute(
      agencyId
    )}/users/${userId}/notifications/unsubscribe`;
    return this.http.post(url, { types: ['desktop'] });
  }

  public dismissHelpMessage(helpMsg: string): Observable<any> {
    const url = `/help/messages/${helpMsg}`;
    return this.http.post<any>(url, { message: helpMsg });
  }

  public absorb(params): Observable<any> {
    const url = `/users/absorb.json`;
    return this.http.post<any>(url, params);
  }

  public setPreference(
    userId: string,
    preferences: UserPreferences
  ): Observable<any> {
    const url = `/users/${userId}/preferences.json`;
    return this.http.patch(url, { preferences });
  }

  public create(agencyId: string, user: NewUserParams): Observable<UserModel> {
    const url = `${AgencyModel.getRoute(agencyId)}/users.json`;
    return this.http.post<UserModel>(url, { user }).pipe(
      tap(() => {
        this.userCountChanged$.next();
      })
    );
  }

  public removeCompanyAccess(
    user_id: string,
    companyId: string
  ): Observable<any> {
    const url = `/companies/${companyId}/modify_user_access.json`;
    return this.http.put(url, { user_id, access: false }).pipe(
      tap(() => {
        this.userCountChanged$.next();
      })
    );
  }

  public addCompanyAccess(user_id: string, companyId: string): Observable<any> {
    const url = `/companies/${companyId}/modify_user_access.json`;
    return this.http.put(url, { user_id, access: 'true' }).pipe(
      tap((a) => {
        this.userCountChanged$.next();
      })
    );
  }

  public rolesByEmail(userId: string | number, email: string): Observable<any> {
    let url = `/users/${userId}/roles_by_emails`;
    url = this.paramsService.appendToString(url, { email });
    return this.http.get(url);
  }

  public resendInvitation(invitationUrl): Observable<UserModel> {
    return this.http.put<any>(invitationUrl, {});
  }

  public invite(companyId: string): Observable<{ users: any[] }> {
    const url = `/companies/${companyId}/users/invite.json`;
    return this.http.get<{ users: any[] }>(url);
  }

  public inviteToCompany(
    companyId: string,
    userId: string,
    invited: boolean
  ): Observable<any> {
    const url = `/companies/${companyId}/modify_user_access.json`;
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('access', invited ? 'true' : 'false');
    return this.http.put(url, formData);
  }

  public resendEmailVerification(agencyId: string): Observable<any> {
    const url = `/a/${agencyId}/users/resend_email_verification.json`;
    return this.http.put(url, {});
  }
  public updateLastSignIn(): Observable<any> {
    const url = `/users/update_last_sign_in`;
    return this.http.post(url, {});
  }
}
