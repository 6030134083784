import { PostCallSurveySetting } from '../interfaces/post-call-survey-setting';
import { Recording } from '../interfaces/recording';
import { TrackingNumber } from '../interfaces/tracking-number';
import { CallScoreCriteria } from './call-score-criteria.model';
import { ChatConfig } from './chat-config.model';
import { ContactConfig } from './contact-config.model';
import { Country } from './country.model';
import { AutomationRuleModel } from './automation-rule.model';
import { Tag } from './tag.model';
import { Multiswaps } from './multiswaps.model';
import { Tracker } from './tracker.model';
import { TranscriptionCriteria } from './transcription-criteria.model';

export class CompanyModel {
  public id: string;
  public resource_id?: string;
  public name?: string;

  public active_recording_call_flows?: boolean;
  public active_call_flow_count?: number;
  public active_numbers_count?: number;
  public active_tracker_count?: number;
  public agents?: any;
  public auto_scoring_unprompted?: any;
  public auto_scoring?: any;
  public business_hours?: any;
  public call_flows?: any;
  public company_access?: boolean;
  public created_at?: string;
  public default_number_natl_format?: string;
  public default_number?: any;
  public disabled?: boolean;
  public disabled_at?: string;
  public dni_active?: boolean;
  public form_capture_call_number?: string;
  public form_capture?: boolean;
  public form_capture_blacklists?: any;
  public form_capture_blacklists_attributes?: any;
  public form_capture_source?: 'ppc' | 'google_ads';
  public form_capture_outbound_number_type?: string;
  public form_capture_url_scope?: 'exclude' | 'only' | 'all';
  public form_capture_urls?: {
    _destroy?: boolean;
    url?: string;
    id?: number;
  }[];
  public form_capture_urls_attributes?: any;
  public form_capture_sms_number?: any;
  public form_capture_outbound_number_id?: any;
  public form_count?: number;
  public form_capture_alerts?: any[];
  public form_email_users?: any[];
  public has_active_call_flows?: boolean;
  public has_active_session_tracker?: boolean;
  public has_active_google_ad_extension_tracker?: boolean;
  public has_any_session_tracker?: boolean;
  public hipaa?: boolean;
  public keyword_spotting_enabled?: boolean; // https://callrail.atlassian.net/browse/CR-29176
  public automation_rules_enabled?: boolean;
  public keyword_spotting_sets?: AutomationRuleModel[]; // https://callrail.atlassian.net/browse/CR-29177
  public automation_rules?: AutomationRuleModel[];
  public keyword_spotting_sets_count?: number; // https://callrail.atlassian.net/browse/CR-29177
  public automation_rules_count?: number;
  public number_count?: number;
  public recordings?: Recording[] = [];
  public signing_key?: any;
  public sip_endpoints?: any;
  public site_name?: string;
  public multiswaps?: Multiswaps[];
  public tags?: Tag[];
  public time_zone_abbr?: string;
  public time_zone_name?: string;
  public time_zone_display?: string;
  public time_zone_utc_offset?: number;
  public time_zone?: string;
  public trackers?: Tracker[];
  public tracking_numbers?: TrackingNumber[];
  public external_numbers?: TrackingNumber[];
  public transcription_criteria?: TranscriptionCriteria;
  public transcription_enabled?: boolean;
  public white_label?: boolean;
  public post_call_survey_setting?: PostCallSurveySetting;
  public call_flow_tag_ids?: (string | number)[];
  public api_key_used?: boolean;
  public user_count?: number;
  public users_count?: number;
  public automated_lead_classification_enabled?: boolean;
  public automated_lead_classification_criteria?: CallScoreCriteria;
  public company_template_id?: string;
  public pci_redaction_enabled?: boolean;
  public assembly_redaction_options?: {
    secure_info: boolean;
    personal_info: boolean;
    numeric_values: boolean;
    medical_info: boolean;
  };
  public most_recent_chat_at?: string;
  public chats_count?: number;
  public chat_config?: ChatConfig;
  public contact_config?: ContactConfig;
  public attribution_url?: string;
  public swappy_url?: string;
  public access_key: number;
  public inactive_numbers?: string;
  public spam_callers?: string;
  public non_local_callers?: string;
  public us_callers?: string;
  public ca_callers?: string;
  public toll_free_callers?: string;
  public restricted_callers?: string;
  public non_standard_callers?: string;
  public all_callers?: string;
  public pusher_channel?: string;
  public scored_with_keyword_spotting?: boolean;
  public form_capture_record_calls?: boolean;
  public js_last_accessed_at?: string;
  public available_countries?: Country[];
  public external_form_tracking_upsell_prompted?: boolean;

  constructor(fromData?: object) {
    Object.assign(this, fromData);
    // https://callrail.atlassian.net/browse/CR-29176
    this.automation_rules_enabled =
      this.automation_rules_enabled || this.keyword_spotting_enabled;
    // https://callrail.atlassian.net/browse/CR-29177
    this.automation_rules = this.automation_rules || this.keyword_spotting_sets;
    this.automation_rules_count =
      this.automation_rules_count || this.keyword_spotting_sets_count;
  }

  public get route(): string {
    return CompanyModel.getRoute(this.id);
  }

  public static getRoute(id: number | string): string {
    return `/companies/${id}`;
  }
}
