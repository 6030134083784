/* eslint-disable @typescript-eslint/member-ordering */
import { ParamMap } from '@angular/router';
import { SimpleAgency } from '../../interfaces/simple-agency';
import { getSafeUrl } from '@callrail/looky/util';

export class Redirect {
  private hasAgencyId: boolean;
  private leadCenterAgency: boolean;
  private firstAgency: SimpleAgency;
  private agencyRegex = new RegExp(/\/a\/\d{9}/);

  constructor(
    private forceMfaMigration: boolean,
    private queryParams: ParamMap,
    private agencies: SimpleAgency[],
    private lastVisitedUrl: string
  ) {
    this.hasAgencyId = this.agencyRegex.test(lastVisitedUrl);
    this.firstAgency = agencies[0];
    this.leadCenterAgency =
      this.firstAgency.lead_center_first ||
      this.firstAgency.lead_with_lead_center;
  }

  public get returnTo(): string {
    if (this.forceMfaMigration) {
      return this.mfaMigrationUrl;
    }
    if (this.queryParamsPresent()) {
      return this.queryParamReturnTo;
    }
    // If lastVisitedUrl has an agency id in it, redirect to that.
    if (this.hasAgencyId) {
      return this.lastVisitedUrl;
    }
    // Historically `lastVisitedUrl` was only used in Lead Center, and without using base href,
    // so `lastVisitedUrl` could be `/agent-tool` for example.
    if (this.leadCenterAgency) {
      return this.leadCenterUrl;
    }
    if (this.agencies.length > 1) {
      return this.accountCenterUrl;
    }
    return this.analyticsUrl;
  }

  public zendesk(): boolean {
    return (
      this.returnTo.includes('zendesk') || this.returnTo.includes('support')
    );
  }

  private get queryParamReturnTo(): string {
    const returnTo =
      this.queryParams.get('return_to') ??
      this.queryParams.get('user[return_to]') ??
      '';
    return getSafeUrl(returnTo) || this.analyticsUrl;
  }

  private get leadCenterUrl(): string {
    return `/lead-center/a/${this.firstAgency.id}/${this.lastVisitedUrl || ''}`;
  }

  private get accountCenterUrl(): string {
    return '/account-center/choose-account';
  }

  private get analyticsUrl(): string {
    return `/analytics/a/${this.firstAgency.id}`;
  }

  private get mfaMigrationUrl(): string {
    return `/settings/a/${this.firstAgency.id}/mfa-migration`;
  }

  private queryParamsPresent(): boolean {
    return !!(
      this.queryParams.get('return_to') ||
      this.queryParams.get('user[return_to]')
    );
  }
}
