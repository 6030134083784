import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorReporterService } from '../../services/error-reporter/error-reporter.service';

@Injectable()
export class ErrorCatcher implements ErrorHandler {
  constructor(private errorReporter: ErrorReporterService) {}

  public handleError(error) {
    this.errorReporter.report(error);
  }
}
