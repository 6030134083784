<div class="alert-flash-wrapper">
  <div
    *ngFor="let alert of alerts"
    class="alert ng-flash-alert"
    [ngClass]="classForAlertType(alert.type)">
    <i [ngClass]="iconClassFor(alert.type)"> </i>
    <a
      class="alert-close"
      (click)="close(alert)"></a>

    <ng-container *ngIf="!alert.segments; else segmented">
      <span>{{ alert.text }}</span
      >&nbsp;
      <a
        *ngIf="alert.linkText"
        (click)="alert.linkFunction ? alert.linkFunction() : null; close(alert)"
        >{{ alert.linkText }}</a
      >
    </ng-container>

    <ng-template #segmented>
      <ui-structured-alert [segments]="alert.segments"> </ui-structured-alert>
    </ng-template>
  </div>
</div>
