import 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/autolinker/prism-autolinker';

export {};

// Forces prism to run in manual mode
(function (window: any) {
  window.Prism = window.Prism || {};
  window.Prism.manual = true;
})(window || {});
