import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ApiProxyInterceptor implements HttpInterceptor {
  // purpose of this interceptor is to prepend `/api/` to all http client requests the Angular
  // apps make so that while developing in Looky, the requests can be properly proxied to your
  // callrail local server while still maintaining the proper url structures in non dev mode.
  // this interceptor is only used in development
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = req.url;

    if (
      url.match(/\.svg$/) || // Don't proxy svg asset requests
      url.match(/calltrk-user-uploads/) || // dont proxy user uploads
      url.match(/https?:\/\//) // dont proxy external urls
    ) {
      return next.handle(req.clone({ url }));
    }

    // prepend / if its not already
    if (!url.match(/^\//)) {
      url = `/${req.url}`;
    }
    // prepend /api if its not already
    if (!url.match(/^\/api\//)) {
      url = `/api${url}`;
    }

    return next.handle(req.clone({ url }));
  }
}
