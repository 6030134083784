import { Component, OnInit } from '@angular/core';
import { UserSignoutService } from '@callrail/looky/core';
import { AlertFlashService } from '@callrail/looky/ui';

@Component({
  selector: 'anbs-amplify-signout',
  template: '',
})
export class AmplifySignoutComponent implements OnInit {
  constructor(
    public signoutService: UserSignoutService,
    private alertFlashService: AlertFlashService
  ) {}

  ngOnInit() {
    this.alertFlashService.addAlert({
      type: 'success',
      text: 'You have been signed out.',
      crossApp: true,
    });
    this.signoutService.userSignout();
  }
}
