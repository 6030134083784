/* eslint max-len: ["error", { "ignoreStrings": true }] */
import { Alert } from '@callrail/looky/ui';
import { DataAccessErrorMessages } from '../interfaces/data-access-policy';

type DataAccessErrorMessageCollection = {
  [key in DataAccessErrorMessages]: {
    default: Alert;
    whitelabel?: Alert;
  };
};

export const dataAccessErrorMessages: DataAccessErrorMessageCollection = {
  default: {
    default: {
      segments: [
        { text: 'Data from that date selection no longer exists due to our' },
        {
          text: 'data retention policy.',
          link: 'https://support.callrail.com/hc/en-us/articles/5711337552269-CallRail-s-data-retention-policy',
          targetBlank: true,
        },
        {
          text: 'Pick a different date within the last two years.',
        },
      ],
      type: 'danger',
    },
    whitelabel: {
      text: 'Data from that date selection no longer exists due to our data retention policy. Pick a different date within the last two years.',
      type: 'danger',
    },
  },
  dateFilter: {
    default: {
      segments: [
        { text: 'Data from that date selection no longer exists due to our' },
        {
          text: 'data retention policy.',
          link: 'https://support.callrail.com/hc/en-us/articles/5711337552269-CallRail-s-data-retention-policy',
          targetBlank: true,
        },
        {
          text: 'Pick a different date within the last two years.',
        },
      ],
      type: 'danger',
    },
    whitelabel: {
      text: 'Data from that date selection no longer exists due to our data retention policy. Pick a different date within the last two years.',
      type: 'danger',
    },
  },
  comparisonDateFilter: {
    default: {
      text: "There's an invalid date range in your comparison filter. Update your selection and try again.",
      type: 'danger',
    },
  },
  exportAccount: {
    default: {
      type: 'warning',
      segments: [
        { text: 'That month is outside' },
        {
          text: 'our data retention policy.',
          link: 'https://support.callrail.com/hc/en-us/articles/5711337552269-CallRail-s-data-retention-policy',
          targetBlank: true,
        },
        { text: 'Pick a different month within the last two years.' },
      ],
    },
    whitelabel: {
      type: 'warning',
      text: 'That month is outside our data retention policy. Pick a different month within the last two years.',
    },
  },
};
