import { Injectable } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { ReplaySubject } from 'rxjs';

const MAX_WAIT_FOR_COGNITO_MS = 250;

@Injectable({
  providedIn: 'root',
})
export class CognitoTokenService {
  // Use ReplaySubject so it doesn't emit before the token is ready,
  // but once it is ready, it'll replay the token for all new subscribers.
  private idTokenSubject = new ReplaySubject<CognitoIdToken>(1);
  public idToken$ = this.idTokenSubject.asObservable();

  constructor(private readonly authService: AuthenticatorService) {
    // This is not a regular observable. `subscribe` is technically for internal use only, but see
    // https://github.com/aws-amplify/amplify-ui/issues/2314#issuecomment-1191031571
    authService.subscribe(() => {
      const { authStatus } = authService;
      if (authStatus === 'authenticated') {
        this.refreshToken();
      }
    });

    // In case the user is not authenticated through Cognito
    // yet, let's make sure we advance the idToken so the
    // frontend isn't stuck waiting for it.
    setTimeout(() => {
      if (authService.authStatus !== 'authenticated') {
        this.idTokenSubject.next(null);
      }
    }, MAX_WAIT_FOR_COGNITO_MS);
  }

  public refreshToken(): void {
    const token = this.getIdToken();
    if (token) {
      this.idTokenSubject.next(token);
    }
  }

  private getIdToken(): CognitoIdToken | undefined {
    const session = this.authService.user?.getSignInUserSession();
    return session?.getIdToken();
  }
}
