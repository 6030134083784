/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { DocumentRefService } from '@callrail/looky/util';
import {
  COLOR_VALUES,
  THEME_CALLRAIL,
  THEME_BASE,
} from '../../constants/themes';
import { WhiteLabelAttributes } from './white-label-attributes';

@Injectable({
  providedIn: 'root',
})
export class CssVariablesService {
  constructor(private documentRefService: DocumentRefService) {}

  public buildWLTheme(brandColors: WhiteLabelAttributes = {}): void {
    let customTheme;

    // if brand colors are defined, add those colors in addition to the base (gray) theme:
    // otherwise, leave customTheme  blank and we'll jsut provide that base theme.
    if (brandColors.white_label_color) {
      customTheme = this.customBrand(brandColors);
    }
    this.applyTheme(customTheme);
    this.addWlBodyClasses(brandColors.white_label_color_luminance_class);
  }

  public buildCallrailTheme(): void {
    // if account is callrail-aware, add the callrail brand colors on top of the base (gray) theme:
    this.applyTheme(THEME_CALLRAIL);
    this.removeWlBodyClasses();
  }

  private applyTheme(overrides?): void {
    // always start with the base theme, then add overrides and extra color properties to the base:
    const theme = { ...THEME_BASE, ...overrides };
    this.addVars(theme);
  }

  private get document() {
    return this.documentRefService.nativeDocument();
  }

  private addWlBodyClasses(wlColor: string): void {
    this.document.body.classList.add('white-label');
    this.document.body.classList.add(wlColor);
  }

  private removeWlBodyClasses(): void {
    this.document.body.classList.remove('white-label');
  }

  private customBrand(brandColors: WhiteLabelAttributes) {
    let navBackground = COLOR_VALUES['white'];
    let navForeground = brandColors.white_label_icon_color;
    let buttonBackground = COLOR_VALUES['mine-shaft'];
    let buttonForeground = COLOR_VALUES['white'];

    if (brandColors.white_label_color_luminance < 200) {
      navBackground = brandColors.white_label_color;
      navForeground = COLOR_VALUES['white'];
    }
    if (brandColors.white_label_color_luminance < 245) {
      buttonBackground = brandColors.white_label_color;
      buttonForeground = brandColors.white_label_icon_color;
    }

    return {
      '--color-wl-primary': this.format(brandColors.white_label_color),
      '--color-wl-secondary': this.format(brandColors.white_label_icon_color),

      '--color-wl-header-background': this.format(navBackground),
      '--color-wl-header-foreground': this.format(navForeground),

      '--color-wl-button-background': this.format(buttonBackground),
      '--color-wl-button-foreground': this.format(buttonForeground),
    };
  }

  private addVars(vars): void {
    const document = this.documentRefService.nativeDocument();
    for (const key of Object.keys(vars)) {
      document.documentElement.style.setProperty(key, vars[key]);
    }
  }

  private format(value): string {
    value = value.replace(/^#+/, '');
    if (value.match(/^([0-9a-fA-f]{6}|[0-9a-fA-f]{3})$/)) {
      // valid hex
      return `#${value}`;
    } else {
      return value; // "0,0,0" or "red|blue|etc"
    }
  }
}
