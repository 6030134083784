<sh-layout-alert-flash></sh-layout-alert-flash>

<div class="container flex row-flex u-no-margin">
  <div class="flex-half sign-in-form">
    <div class="login-controls">
      <img
        [class.logo]="(isWl$ | async) === false"
        [class.logo-white-label]="isWl$ | async"
        [alt]="logoAlt$ | async"
        [src]="logo$ | async" />
      <router-outlet></router-outlet>
    </div>
  </div>
  <div
    class="flex-half announcements"
    *ngIf="(isWl$ | async) === false">
    <div class="card">
      <builder-component name="login-announcement"></builder-component>
    </div>
  </div>
</div>
