/** @cabooseInclude */
import { Injectable } from '@angular/core';
import { AppRoutes, WindowRefService } from '@callrail/looky/util';

import { defaultError } from '../../constants/default-error';
import { Alert, AlertSegment } from '../../models/alert.model';
import { AlertID } from '../../type-aliases/alert-id';
import { CrossAppAlertService } from './cross-app-alert/cross-app-alert.service';

@Injectable({
  providedIn: 'root',
})
export class AlertFlashService {
  constructor(
    private crossAppAlertService: CrossAppAlertService,
    private windowRefService: WindowRefService
  ) {}

  private _alerts: Alert[] = [];

  /**
   * To add a new alert use the `AlertFlashService#addAlert` method.
   */
  public get alerts(): Alert[] {
    return this._alerts;
  }

  /**
   * Use this for displaying a new flash alert.
   * @param alert The alert you want to display.
   *
   * Under the hood, this method will also check whether the alert should be processed like a cross-app alert
   */
  public addAlert(alert: Alert): void {
    if (this.crossAppAlertService.shouldStoreNewAlert(alert)) {
      this.crossAppAlertService.storeAlert(alert);
    } else {
      this.showAlert(alert);
    }
  }

  /**
   * Use for adding a success alert without importing the Alert model
   */
  public addSuccess(
    text: string,
    showAfterRouteChange?: boolean,
    timeout?: number,
    crossApp?: boolean,
    app?: AppRoutes
  ): void {
    this.addAlert(
      new Alert({
        type: 'success',
        text,
        showAfterRouteChange,
        timeout,
        crossApp,
        app,
      })
    );
  }

  /**
   * Adds a basic info alert.
   * @param text The message displayed.
   * @param showAfterRouteChange Whether the message is displayed after a redirect.
   */
  public addInfo(
    text: string,
    showAfterRouteChange?: boolean,
    timeout?: number,
    crossApp?: boolean,
    app?: AppRoutes
  ): void {
    this.addAlert(
      new Alert({
        type: 'info',
        text,
        timeout,
        showAfterRouteChange,
        crossApp,
        app,
      })
    );
  }

  /**
   * Use for adding a warning alert without import the Alert model.
   */
  public addWarning(
    text: string,
    linkText?,
    linkFunction?,
    showAfterRouteChange?: boolean,
    timeout?: number,
    crossApp?: boolean,
    app?: AppRoutes
  ): void {
    this.addAlert(
      new Alert({
        type: 'warning',
        text,
        linkText,
        linkFunction,
        showAfterRouteChange,
        timeout,
        crossApp,
        app,
      })
    );
  }

  /**
   * Use for adding a danger alert without importing the Alert model.
   * Defaults to
   * "We're sorry, something went wrong. Please try again or contact support if the issue persists."
   */
  public addDanger(
    text: string | AlertSegment[] = defaultError,
    showAfterRouteChange?: boolean,
    timeout?: number,
    crossApp?: boolean,
    app?: AppRoutes
  ): void {
    if (Array.isArray(text)) {
      this.addAlert(
        new Alert({
          type: 'danger',
          segments: text,
          showAfterRouteChange,
          timeout,
          crossApp,
          app,
        })
      );
    } else {
      this.addAlert(
        new Alert({
          type: 'danger',
          text,
          showAfterRouteChange,
          timeout,
          crossApp,
          app,
        })
      );
    }
  }

  public clearAllAlerts(): void {
    this._alerts.splice(0, this._alerts.length);
  }

  public clearAlertById(id: AlertID): void {
    const alert = this._alerts.find((a) => a.id === id);
    this.clearAlert(alert);
  }

  /**
   * Used primarily by the `V2AlertFlashComponent` for removing an
   * alert when a user clicks the `X`.
   */
  public clearAlert(alert: Alert): void {
    const i = this._alerts.indexOf(alert);
    if (i > -1) {
      if (this._alerts[i].callback) {
        this._alerts[i].callback();
      }
      this._alerts.splice(i, 1);
    }
  }

  /**
   * Attempt to display alerts that are currently in local storage
   */
  public showAlertsFromStorage(): void {
    if (this.windowRefService.isRingCentralIframe) {
      return;
    }
    const alerts = this.crossAppAlertService.pullAlertsToShowFromStorage();
    alerts.forEach((alert) => this.showAlert(alert));
  }

  /**
   * Displays the alert, no questions asked.
   * @param alert the alert to display
   *
   * This is needed in addition to addAlert because addAlert needs to check for cross-app alerts before showing them,
   * since it's the entry point for all alerts coming into the service
   */
  private showAlert(alert: Alert): void {
    this._alerts.splice(0, 0, alert);
    this._alerts.splice(3);

    if (alert.timeout) {
      setTimeout(() => this.clearAlert(alert), alert.timeout * 1000);
    }
  }
}
