import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StructuredAlertComponent } from './structured-alert.component';

@NgModule({
  declarations: [StructuredAlertComponent],
  exports: [StructuredAlertComponent],
  imports: [CommonModule],
})
export class StructuredAlertModule {}
