import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { DocumentRefService } from '@callrail/looky/util';
import { delay, from, Observable, of, switchMap, throwError } from 'rxjs';

import { HeaderTokenAuthTokenResponse } from '../../interfaces/header-token-auth-token-response';
import { ErrorReporterService } from '../error-reporter/error-reporter.service';

declare const SM: any; // semrush js sdk

type ClientMethod = 'getAccessToken';

interface SemrushSdk {
  client: (methodName: ClientMethod, ...params) => Promise<string>;
  init: () => Promise<unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class SemrushIframeService {
  private sdkLoadRetries = 0;
  private maxSdkLoadRetries = 28; // 28 * 250ms = 7seconds

  constructor(
    private documentRefService: DocumentRefService,
    private errorReporterService: ErrorReporterService,
    private http: HttpClient,
    @Optional() @Inject('sm') private sm?: any
  ) {}

  public get sdk(): SemrushSdk {
    return this.sm || SM;
  }

  public tokenExchange(
    path: string,
    providerToken: string
  ): Observable<HeaderTokenAuthTokenResponse> {
    return this.http.post<HeaderTokenAuthTokenResponse>(path, {
      jwt: providerToken,
    });
  }

  public appendScriptToBody(): void {
    const script = this.documentRefService
      .nativeDocument()
      .createElement('script');
    // this file was downloaded directly from https://static.semrush.com/app-center/sdk.js
    // and then minified with an online minifier https://www.toptal.com/developers/javascript-minifier/
    // and then uploaded to our cdn following these instructions https://callrail.atlassian.net/l/c/SvRVYswq
    script.src =
      'https://s3.amazonaws.com/public-assets.calltrk.com/web-assets/semrush-app-center-sdk.min.js';
    script.type = 'text/javascript';
    this.documentRefService.nativeDocument().body.appendChild(script);
  }

  public get providerToken(): Observable<string | Error> {
    return from(
      this.sdk.client('getAccessToken').catch((error) => {
        return this.handleSdkError(error);
      })
    );
  }

  public get initSdk(): Observable<unknown | Error> {
    return from(
      this.sdk.init().catch((error) => {
        return this.handleSdkError(error);
      })
    );
  }

  // this function is necessary because the sdk script is being added to the index.html after page load.
  // We need to wait for the sdk javascript to have run so the global `SM` object is available to use
  public get waitForSdk(): Observable<SemrushSdk> {
    try {
      return of(this.sdk);
    } catch (error) {
      return of({}).pipe(
        delay(250),
        switchMap(() => {
          if (this.sdkLoadRetries > this.maxSdkLoadRetries) {
            return throwError(() => error); // SM not defined
          } else {
            this.sdkLoadRetries += 1;
            return this.waitForSdk;
          }
        })
      );
    }
  }

  private handleSdkError(error): Error {
    const initialCode = error.status;
    error.status = 500; // ensure we _do_ actually badger this no matter what code it had initially
    const metadata = { uncaught: true, code: initialCode };
    this.errorReporterService.report(this.errorWithName(error), metadata);
    return error;
  }

  private errorWithName(error: Error): Error {
    // for easier scanning in Honeybadger UI and team/individual assignment
    error.name = `SemrushIframeService::${error.name}`;
    return error;
  }
}
