import { Injectable } from '@angular/core';
import Honeybadger from '@honeybadger-io/js';

@Injectable({
  providedIn: 'root',
})
export class HoneyBadgerService {
  public get hb(): any {
    return Honeybadger;
  }
}
